export default {
  get<D>(name: string, defaultData: D): D {
    if (typeof localStorage !== 'undefined') {
      const item = localStorage.getItem(name);
      if (item && item !== 'undefined' && item !== 'null' && item != null) {
        try {
          return JSON.parse(item);
        } catch (err) {
          return defaultData;
        }
      } else {
        return defaultData;
      }
    } else {
      return defaultData;
    }
  },
  save(name: string, data: unknown, callback: () => void, err: (message: string) => void): void {
    if (typeof localStorage !== 'undefined') {
      try {
        localStorage.setItem(name, JSON.stringify(data));
        if (callback) callback();
      } catch (e) {
        if (err) {
          err(`Unable to store ${name} in localStorage due to ${e.name}`);
        }
      }
    }
  },
  remove(name: string): void {
    // name - Local storage key
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(name);
    }
  },
  clear(): void {
    if (typeof localStorage !== 'undefined') {
      localStorage.clear();
    }
  },
};
