import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';

import { signInSuccess, signOut, tokenRefreshSuccess } from '../actions/Auth';
import { profileUpdateEmailSuccess } from '../actions/Users';
import { AuthState } from '../interfaces/Auth';


const INITIAL_STATE: AuthState = {
  token: '',
};

type Builder = ActionReducerMapBuilder<AuthState>
const actionsMap = (builder: Builder): Builder => builder
  .addCase(
    signInSuccess,
    (state, { payload: { token } }) => ({
      ...state,
      token,
    }),
  )
  .addCase(
    tokenRefreshSuccess,
    (state, { payload: { token } }) => ({
      ...state,
      token,
    }),
  )
  .addCase(
    signOut,
    () => INITIAL_STATE,
  )
  .addCase(
    profileUpdateEmailSuccess,
    () => INITIAL_STATE,
  );

export default createReducer(
  INITIAL_STATE,
  actionsMap,
);
