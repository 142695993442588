import { call, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';

import fetchApi from '../../utils/fetchApi';
import {
  productsGetItemRequest,
  productsGetItemRequestFailed,
  productsGetItemRequestSuccess,
  productsGetItems,
  productsGetItemsFailed,
  productsGetItemsPayload,
  productsGetItemsSuccess,
} from '../actions/Product';
import { GetProductsAPIResult } from '../../interfaces/Products';


function* getItems(
  { payload }: PayloadAction<productsGetItemsPayload>,
): SagaIterator {
  try {
    const { response, success }: GetProductsAPIResult = yield call(
      fetchApi,
      {
        payloadUrl: `products/category/${payload.category}/?page=${payload.page}`,
      },
    );
    if (success) {
      yield put(productsGetItemsSuccess(response));
      return;
    }
  } catch ({ message }) {
    yield put(productsGetItemsFailed({ message }));
  }
}

function* getItem(
  { payload }: PayloadAction<string>,
): SagaIterator {
  try {
    const { response, success } = yield call(
      fetchApi,
      {
        payloadUrl: `products/${payload}/`,
      },
    );
    if (success) {
      yield put(productsGetItemRequestSuccess(response));
      return;
    }
  } catch ({ message }) {
    yield put(productsGetItemRequestFailed({ message }));
  }
}


export default function* (): SagaIterator {
  yield takeEvery(productsGetItems.type, getItems);
  yield takeEvery(productsGetItemRequest.type, getItem);
}
