import { createMuiTheme, ThemeOptions } from '@material-ui/core';
import { ruRU } from '@material-ui/core/locale';

import lightVariant from './variants';
import typography from './typography';
import overrides from './overrides';
import breakpoints from './breakpoints';
import props from './props';
import shadows from './shadows';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    header: typeof lightVariant.header;
    sidebar: typeof lightVariant.sidebar;
    body: typeof lightVariant.body;
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    header?: typeof lightVariant.header;
    sidebar?: typeof lightVariant.sidebar;
    body?: typeof lightVariant.body;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default (options: ThemeOptions) => createMuiTheme(
  {
    spacing: 4,
    breakpoints,
    overrides,
    // @ts-ignore
    props,
    // @ts-ignore
    typography,
    // @ts-ignore
    shadows,
    ...options,
  },
  'Light',
  ruRU,
);
