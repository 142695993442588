import { createAction } from '@reduxjs/toolkit';
import {
  DIALOGS_GETCOURSE_FORM_SUBMIT,
  DIALOGS_GETCOURSE_FORM_SUBMIT_FAILED,
  DIALOGS_GETCOURSE_FORM_SUBMIT_SUCCESS,
  DIALOGS_GETCOURSE_TOGGLE,
  DIALOGS_GETCOURSE_TOGGLE_HIDE,
  DIALOGS_GETCOURSE_TOGGLE_SHOW,
} from '../../actionTypes';


export const getcourseFormSubmit = createAction<
  unknown,
  typeof DIALOGS_GETCOURSE_FORM_SUBMIT
  >(DIALOGS_GETCOURSE_FORM_SUBMIT);

export const getcourseFormSubmitSuccess = createAction<
  void,
  typeof DIALOGS_GETCOURSE_FORM_SUBMIT_SUCCESS
  >(DIALOGS_GETCOURSE_FORM_SUBMIT_SUCCESS);

export type getcourseFormSubmitFailedPayload = {message: string}
export const getcourseFormSubmitFailed = createAction<
  getcourseFormSubmitFailedPayload,
  typeof DIALOGS_GETCOURSE_FORM_SUBMIT_FAILED
  >(DIALOGS_GETCOURSE_FORM_SUBMIT_FAILED);

export const getcourseDialogToggle = createAction<
  void,
  typeof DIALOGS_GETCOURSE_TOGGLE
  >(DIALOGS_GETCOURSE_TOGGLE);

export const getcourseDialogToggleShow = createAction<
  void,
  typeof DIALOGS_GETCOURSE_TOGGLE_SHOW
  >(DIALOGS_GETCOURSE_TOGGLE_SHOW);

export const getcourseDialogToggleHide = createAction<
  void,
  typeof DIALOGS_GETCOURSE_TOGGLE_HIDE
  >(DIALOGS_GETCOURSE_TOGGLE_HIDE);
