import React, {
  FC, useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';
import ReactHtmlParser from 'react-html-parser';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import styles from './styles';
import { activationDialogToggleHide, activationFormSubmit } from '../../store/actions/Dialogs';
import { RootState } from '../../store';
import fetchApi from '../../utils/fetchApi';

const initialConfigText = '';

const useStyles = makeStyles(styles, { name: 'ActivationDialog' });

const ActivationDialog: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector(({ Users }: RootState) => Users);
  const {
    is_in_partners_group: isPartner,
  } = user || {};

  const { isOpen } = useSelector(({ Dialogs: { activation } }: RootState) => activation);
  const {
    handleSubmit,
  } = useForm();
  const [configText, setConfigText] = useState(initialConfigText);

  const loadData = useCallback(
    async (payloadUrl: string): Promise<void> => {
      const { response, success } = await fetchApi<string>({
        payloadUrl,
      });
      if (!success) {
        setConfigText(initialConfigText);
      }

      if (success && response) {
        setConfigText(response);
      }
    },
    [setConfigText, initialConfigText],
  );

  useMount(() => {
    loadData('/configs/get_config/?config_name=activation_dialog_body');
  });

  const handleClose = useCallback(() => {
    dispatch(activationDialogToggleHide());
  }, [dispatch]);

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (data) => {
      dispatch(activationFormSubmit({
        payload: JSON.stringify(data),
      }));
    },
    [dispatch],
  );
  return (
    <div>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">
            {t('Partnership')}
          </DialogTitle>
          <DialogContent>
            <>
              {ReactHtmlParser(configText)}
            </>
            <DialogActions>
              <>
                {isPartner ? (
                  <Button color="primary" type="submit">
                    {t('deactivatePartnership')}
                  </Button>
                ) : (
                  <Button color="primary" type="submit">
                    {t('activatePartnership')}
                  </Button>
                )}
                <Button color="primary" onClick={handleClose}>
                  {t('close')}
                </Button>
              </>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
};

export default ActivationDialog;
