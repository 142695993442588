import React, {
  FC, memo, useCallback, useState,
} from 'react';
// import PersonIcon from '@material-ui/icons/Person';
import {
  IconButton, makeStyles, Menu, MenuItem,
  Avatar,
} from '@material-ui/core';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { useMount } from 'react-use';
import { useDispatch } from 'react-redux';
import fetchApi from '../../../utils/fetchApi';

import { PropsMenu, MenuObject } from '../types';
import {
  subscriptionDialogToggleShow,
  withdrawDialogToggleShow,
} from '../../../store/actions/Dialogs';
import { signOut } from '../../../store/actions/Auth';
import styles from '../styles';
import MenuIcon from './MenuIcon';

const useStyles = makeStyles(styles, { name: 'UserMenu' });
const initialMenu: MenuObject[] = [];

const UserMenu: FC<PropsMenu> = (props) => {
  const classes = useStyles();
  // const history = useHistory();
  const dispatch = useDispatch();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const {
    avatarUrl = '',
  } = props;
  const [menu, setMenu] = useState(initialMenu);

  const loadData = useCallback(
    async (payloadUrl: string): Promise<void> => {
      const { response, success } = await fetchApi<MenuObject[]>({
        payloadUrl,
      });
      if (!success) {
        setMenu(initialMenu);
      }

      if (success && response) {
        // const { results } = response;
        setMenu(response);
      }
    },
    [setMenu, initialMenu],
  );

  useMount(() => {
    loadData('/menu/get-dropdown-menu/');
  });

  const toggleMenu = useCallback(
    (event) => {
      setAnchorMenu(event.currentTarget);
    },
    [setAnchorMenu],
  );

  const closeMenu = useCallback(
    () => {
      setAnchorMenu(null);
    },
    [setAnchorMenu],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubscription = useCallback(
    () => {
      closeMenu();
      dispatch(subscriptionDialogToggleShow());
    },
    [dispatch, closeMenu],
  );

  const handleWithdraw = useCallback(
    () => {
      closeMenu();
      dispatch(withdrawDialogToggleShow());
    },
    [dispatch, closeMenu],
  );

  const handlesSignOut = useCallback(
    () => {
      closeMenu();
      dispatch(signOut());
    },
    [dispatch, closeMenu],
  );

  return (
    <>
      <IconButton
        className={classes.iconButton}
        aria-owns={anchorMenu ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Avatar alt="avatar" src={avatarUrl} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <>
          {menu.map((
            {
              title, icon, url,
            },
          ) => (
            <>
              {url.includes('handle') ? (
                <>
                  {url.includes('handleSubscription') ? (
                    <MenuItem onClick={handleSubscription}>
                      <MenuIcon iconName={icon} />
                      &nbsp;
                      {title}
                    </MenuItem>
                  ) : (
                    <>
                    </>
                  )}
                  {url.includes('handleWithdraw') ? (
                    <MenuItem onClick={handleWithdraw}>
                      <MenuIcon iconName={icon} />
                      &nbsp;
                      {title}
                    </MenuItem>
                  ) : (
                    <>
                    </>
                  )}
                  {url.includes('handlesSignOut') ? (
                    <MenuItem onClick={handlesSignOut}>
                      <MenuIcon iconName={icon} />
                      &nbsp;
                      {title}
                    </MenuItem>
                  ) : (
                    <>
                    </>
                  )}
                </>
              ) : (
                <RouterNavLink
                  className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button"
                  to={url}
                  onClick={closeMenu}
                >
                  <MenuIcon iconName={icon} />
                  &nbsp;
                  {title}
                </RouterNavLink>
              )}
            </>
          ))}
        </>
      </Menu>
    </>
  );
};

export default memo(UserMenu);
