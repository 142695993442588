import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Chip,
  Drawer,
  List,
  ListItem,
  makeStyles,
} from '@material-ui/core';
import { Globe } from 'react-feather';
import MenuItems from './Subcomponents/MenuItems';
import styles, { drawerWidth } from './styles';
import { Props } from './types';


const useStyles = makeStyles(styles, { name: 'Sidebar' });

const Sidebar: FC<Props> = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const {
    onClick,
    ...other
  } = props;

  return (
    <Drawer className={classes.drawer} PaperProps={{ style: { width: drawerWidth } }} {...other}>
      <ListItem className={classes.brand}>
        <Globe className={classes.brandIcon} />
        <Box ml={1}>
          {t('UNIVERSITET')}
          <br />
          <Chip label={t('SALES')} className={classes.brandChip} />
        </Box>
      </ListItem>
      <List disablePadding className={classes.list}>
        <Box py={2.5}>
          <MenuItems onClick={onClick} />
        </Box>
      </List>
      {/* <Box py={2.75} px={4} className={classes.sidebarFooter}>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar alt={fullName} src={avatarUrl} />
          </Grid>
          <Grid item>
            <Typography variant="body2" className={classes.sidebarFooterText}>
              {firstName}
            </Typography>
            <Typography variant="caption" className={classes.sidebarFooterSubText}>
              Чемпион продаж
            </Typography>
          </Grid>
        </Grid>
      </Box> */}
    </Drawer>
  );
};

export default memo(Sidebar);
