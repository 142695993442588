import React, { FC, useCallback } from 'react';
import { Tooltip, Typography, makeStyles } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import InfoIcon from '@material-ui/icons/Info';
import styles from '../styles';

import { PackLabelProps } from '../types';


const useStyles = makeStyles(styles, { name: 'SubscriptionDialog' });

const PackLabel: FC<PackLabelProps> = ({
  title,
  oldPrice,
  newPrice,
  description,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleInfoClick = useCallback((e) => {
    e.preventDefault();
    setOpen(true);
  }, [setOpen]);

  const handleInfoClose = useCallback((e) => {
    e.preventDefault();
    setOpen(false);
  }, [setOpen]);

  return (
    <Typography component="span" className={classes.packLabel}>
      <span>
        {`${title} (`}
        {Number(oldPrice) !== Number(newPrice) && (
          <span
            className={classes.packLabelOldPrice}
            style={{ verticalAlign: 'unset' }}
          >
            {`${oldPrice}`}
          </span>
        )}
        <span style={{ verticalAlign: 'unset' }}>&nbsp;</span>
        <span style={{ verticalAlign: 'unset' }}>{`${newPrice} SLC`}</span>
        )
      </span>
      {/* <Typography component="span" className={classes.packLabelText}>)</Typography> */}
      <Tooltip
        disableFocusListener
        title={ReactHtmlParser(description)}
        open={open}
        onClose={handleInfoClose}
      >
        <InfoIcon onClick={handleInfoClick} />
      </Tooltip>
    </Typography>
  );
};

export default PackLabel;
