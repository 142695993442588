/* eslint-disable max-len */
import { createAction } from '@reduxjs/toolkit';

import {
  PRODUCT_CATEGORIES_GET_ITEMS,
  PRODUCT_CATEGORIES_GET_ITEMS_SUCCESS,
  PRODUCT_CATEGORIES_GET_ITEMS_FAILED,
} from '../actionTypes';
import { ProductCategory } from '../interfaces/ProductCategories';


// Items
export const productCategoriesGetItems = createAction<
  void,
  typeof PRODUCT_CATEGORIES_GET_ITEMS
>(PRODUCT_CATEGORIES_GET_ITEMS);

export const productCategoriesGetItemsSuccess = createAction<
  ProductCategory[],
  typeof PRODUCT_CATEGORIES_GET_ITEMS_SUCCESS
>(PRODUCT_CATEGORIES_GET_ITEMS_SUCCESS);

export type productCategoriesGetItemsFailedPayload = { message: string }
export const productCategoriesGetItemsFailed = createAction<
productCategoriesGetItemsFailedPayload,
  typeof PRODUCT_CATEGORIES_GET_ITEMS_FAILED
>(PRODUCT_CATEGORIES_GET_ITEMS_FAILED);
