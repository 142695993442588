import React, {
  FC, Fragment, useCallback, useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Input,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import { SubscriptionResponse, SubscriptionPackage, PromoCodeResponse } from './types';
import PackLabel from './Subcomponents/PackLabel';
import PaySystemLabel from './Subcomponents/PaySystemLabel';
import styles from './styles';
import { subscriptionDialogToggleHide, subscriptionFormSubmit } from '../../store/actions/Dialogs';
import fetchApi from '../../utils/fetchApi';
import { RootState } from '../../store';

const useStyles = makeStyles(styles, { name: 'SubscriptionDialog' });

const SubscriptionDialog: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const initialDataPackages: SubscriptionPackage[] = [];
  const initialValidFor: number[] = [];
  const [packages, setPackages] = useState(initialDataPackages);
  const [paySystems, setPaySystems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  const [packLoaded, setPackLoaded] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeText, setPromoCodeText] = useState('');
  const [value, setValue] = React.useState('');
  const [discount, setDiscount] = React.useState(0);
  const [validFor, setValidFor] = React.useState(initialValidFor);
  const [paySystemValue, setPaySystemValue] = React.useState('');
  const dispatch = useDispatch();
  const { user } = useSelector(({ Users }: RootState) => Users);
  const {
    wallet,
  } = user || {};
  const { balance = 0 } = wallet || {};

  const { isOpen } = useSelector(({ Dialogs: { subscription } }: RootState) => subscription);
  const {
    register,
    handleSubmit,
    setError,
  } = useForm();

  const handleChange = useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [],
  );

  const handlePaySystemChange = useCallback(
    (event) => {
      setPaySystemValue(event.target.value);
    },
    [setPaySystemValue],
  );

  const loadPackages = async (): Promise<void> => {
    const { response, status } = await fetchApi<SubscriptionResponse>({
      method: 'GET',
      payloadUrl: '/subscription/packages/',
    });
    if (status === 200) {
      setPackages(response.packages);
      setPaySystems(response.pay_systems);
      setValue(response.packages[0].pk.toString());
      setPaySystemValue(get(response, 'pay_systems[0][0]', 'F'));
      setFormLoading(false);
      setPackLoaded(true);
    }
    //  else {
    //   setTimeout(loadPackages, 1000);
    // }
  };

  useEffect(() => {
    if (isOpen && !packLoaded) {
      loadPackages();
    }
  });

  const handleClose = useCallback(() => {
    dispatch(subscriptionDialogToggleHide());
  }, [dispatch]);

  const checkPromoCode = async (code: string): Promise<void> => {
    const payload = {
      code,
      subscriptionPk: value,
    };
    setPromoCodeText(t('searching_promo'));
    const { response, status } = await fetchApi<PromoCodeResponse>({
      method: 'POST',
      payloadUrl: '/promocodes/check-code/',
      payload: JSON.stringify(payload),
    });
    if (status === 200) {
      setDiscount(response.discount);
      setValidFor(response.valid_for_subscriptions);
      setPromoCodeText(response.description);
    }
  };

  const promoCodehandleChange = useCallback((event) => {
    setPromoCode(event.target.value);
    checkPromoCode(event.target.value);
  }, [promoCode, setPromoCode, value, promoCode]);

  const calcPrice = (pk: number, p: number, d: number): string | number => {
    if (validFor.length === 0) return (p - ((p * d) / 100)).toFixed(2);
    if ((validFor.indexOf(Number(pk)) !== -1)) return (p - ((p * d) / 100)).toFixed(2);
    return p;
  };

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (data) => {
      setLoading(true);
      dispatch(subscriptionFormSubmit({
        payload: JSON.stringify(data),
        cbError: setError,
        cbCompleted: () => setLoading(false),
      }));
    },
    [setError, dispatch],
  );
  return (
    <div>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">
            {t('buy_or_upd_subscription')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={formLoading ? classes.loading : ''}>
              {formLoading ? (
                <CircularProgress size="3rem" />
              ) : (
                <>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">
                          {t('choose_subscription')}
                        </FormLabel>
                        <RadioGroup
                          aria-label="package"
                          name="package"
                          value={value}
                          className={classes.packs}
                          onChange={handleChange}
                        >
                          {packages.map(({
                            pk,
                            title,
                            old_price: oldPrice,
                            new_price: newPrice,
                            description,
                          }) => (
                            <Fragment key={pk}>
                              <FormControlLabel
                                // @ts-ignore
                                value={pk.toString()}
                                control={<Radio />}
                                style={{ width: '100%' }}
                                classes={{ label: classes.packLabelText }}
                                label={(
                                  <PackLabel
                                    title={title}
                                    oldPrice={oldPrice}
                                    newPrice={calcPrice(pk, newPrice, discount)}
                                    description={description}
                                  />
                                )}
                                inputRef={register()}
                              />
                            </Fragment>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          {t('PaymentMethod')}
                        </FormLabel>
                        <RadioGroup
                          aria-label="pay_system"
                          name="pay_system"
                          value={paySystemValue}
                          onChange={handlePaySystemChange}
                        >
                          {paySystems.map((
                            [id, label],
                          ) => (
                            <Fragment key={id}>
                              <FormControlLabel
                                value={id}
                                control={<Radio />}
                                label={<PaySystemLabel id={id} title={label} balance={balance} />}
                                inputRef={register()}
                              />
                            </Fragment>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend">
                        {t('PromocodeHint')}
                      </FormLabel>
                      <Input
                        name="promocode"
                        value={promoCode}
                        onChange={promoCodehandleChange}
                        inputRef={register()}
                      />
                      <FormHelperText id="standard-weight-helper-text">{promoCodeText}</FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              {t('cancel')}
            </Button>
            <Button color="primary" variant="outlined" type="submit" disabled={loading} style={{ background: '#1565c0', color: '#fff' }}>
              { loading && (
                <CircularProgress size="1rem" />
              )}
              {t('submit_pay')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default SubscriptionDialog;
