import {
  call, put, takeEvery,
} from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { FormContextValues } from 'react-hook-form';

import {
  contactFormSubmit,
  contactFormSubmitFailed,
  contactFormSubmitSuccess,
} from '../../actions/Dialogs';
import fetchApi from '../../../utils/fetchApi';
import { parseForHookForm } from '../../../utils/parseResponseErrors';
import { ResponseErrors } from '../../../utils/types';


function* contactRequest(
  { payload: { payload, cbError } }: PayloadAction<
    { payload: string | FormData; cbError: FormContextValues['setError'] }
  >,
): SagaIterator {
  try {
    const { response, success, status } = yield call(
      fetchApi,
      {
        method: 'POST',
        payloadUrl: '/contacts/',
        payload,
      },
    );
    if (success) {
      if (!response) {
        yield put(contactFormSubmitSuccess());
      } else {
        // @ts-ignore
        yield put(contactFormSubmitSuccess(response));
      }
    } else {
      parseForHookForm<ResponseErrors>(response, cbError);
      if (status === 503) {
        throw new Error('Сервис недоступен');
      }
    }
  } catch ({ message }) {
    yield put(contactFormSubmitFailed({ message }));
  }
}

export default function* (): SagaIterator {
  yield takeEvery(contactFormSubmit.type, contactRequest);
}
