import React from 'react';
import ReactDOM from 'react-dom';
import { CircularProgress } from '@material-ui/core';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Helmet from 'react-helmet';
import { Router } from 'react-router-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store, { persistor } from './store';
import { history } from './routes';
import './index.css';
import 'react-notifications/lib/notifications.css';
import './services/i18n';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<CircularProgress />} persistor={persistor}>
      <Helmet
        titleTemplate="%s | Sokolov"
        defaultTitle="Sokolov"
      />
      <Router history={history}>
        <React.Suspense fallback="Loading...">
          <App />
        </React.Suspense>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
