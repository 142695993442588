import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { PaySystemLabelProps } from '../types';
import fondyImage from '../../../assets/images/pay-systems/visa.png';
import btcImage from '../../../assets/images/pay-systems/btc.svg';
import walletImage from '../../../assets/images/pay-systems/wallet.png';

const images: {[key: string]: string} = {
  F: fondyImage,
  B: btcImage,
  W: walletImage,
};

const PaySystemLabel: FC<PaySystemLabelProps> = ({ id, title, balance }) => {
  const { t } = useTranslation();

  return (
    <p style={{ display: 'inline-flex' }}>
      <img src={images[id]} alt={title} style={{ height: '25px' }} />
      <Typography style={{ marginLeft: '10px' }}>
        {title}
        {id === 'W' && (
          ` (${t('balance')}: ${balance} SLC)`
        )}
      </Typography>
    </p>
  );
};

export default PaySystemLabel;
