/* eslint-disable @typescript-eslint/explicit-function-return-type */
import defaultReducerFunc from '../../../utils/defaultReducerFunc';
import { WithdrawDialogState } from '../../interfaces/Dialogs';
import {
  withdrawDialogToggle,
  withdrawDialogToggleHide,
  withdrawDialogToggleShow,
  withdrawFormSubmitSuccess,
} from '../../actions/Dialogs';


const INITIAL_STATE: WithdrawDialogState = {
  isOpen: false,
};

const actionsMap = [
  {
    action: withdrawDialogToggle,
    reducer: defaultReducerFunc,
  },
  {
    action: withdrawDialogToggleShow,
    reducer: (state: WithdrawDialogState) => ({
      ...state,
      isOpen: true,
    }),
  },
  {
    action: withdrawDialogToggleHide,
    reducer: (state: WithdrawDialogState) => ({
      ...state,
      isOpen: false,
    }),
  },
  {
    action: withdrawFormSubmitSuccess,
    reducer: (state: WithdrawDialogState) => ({
      ...state,
      isOpen: false,
    }),
  },
];

export default { INITIAL_STATE, actionsMap };
