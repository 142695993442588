/* eslint-disable react/jsx-filename-extension */
import AppsIcon from '@material-ui/icons/Apps';
import { get } from 'lodash';

import { SidebarMenuItem } from './types';
import { RootState } from '../../store';
import { productCategoriesGetItems } from '../../store/actions/ProductCategories';

const menuItems: SidebarMenuItem[] = [
  // {
  //   key: 'home',
  //   name: 'Кабинет',
  //   icon: Monitor,
  //   path: '/',
  // },
  // {
  //   key: 'contacts',
  //   name: 'Контакты',
  //   icon: ContactsIcon,
  //   path: '/contacts',
  // },
  // {
  //   key: 'finances',
  //   name: 'Счет',
  //   icon: LocalAtmIcon,
  //   path: '/finances',
  // },
  // {
  //   key: 'referral-tree',
  //   name: 'Моя команда',
  //   icon: Users,
  //   path: '/my-team',
  // },
  {
    key: 'products',
    name: 'Маркетплейс',
    icon: AppsIcon,
    path: '/products/all',
    children: [],
    getChildren: (state: RootState): SidebarMenuItem[] => get(state, 'ProductCategories.categories', []),
    getChildrenAction: (dispatch): void => {
      dispatch(productCategoriesGetItems());
    },
  },
];

export default menuItems;
