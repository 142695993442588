import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';

import {
  productsGetItem,
  productsGetItemRequestSuccess,
  productsGetItemsSuccess,
} from '../actions/Product';
import { ProductsState } from '../interfaces/Products';


const INITIAL_STATE: ProductsState = {
  products: {
    items: [],
    current: 1,
    count: 1,
    next: null,
    previous: null,
  },
  product: null,
};

type Builder = ActionReducerMapBuilder<ProductsState>
const actionsMap = (builder: Builder): Builder => builder
  .addCase(
    productsGetItemsSuccess,
    (state, { payload: { results, ...other } }) => ({
      ...state,
      products: {
        ...other,
        items: results,
      },
    }),
  )
  .addCase(
    productsGetItem,
    (state, { payload }) => ({
      ...state,
      product: state.products.items.find(({ pk }) => pk === payload) || null,
    }),
  )
  .addCase(
    productsGetItemRequestSuccess,
    (state, { payload }) => ({
      ...state,
      product: payload,
    }),
  );

export default createReducer(
  INITIAL_STATE,
  actionsMap,
);
