import {
  call, put, select, takeEvery,
} from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { FormContextValues } from 'react-hook-form';

import {
  withdrawFormSubmit,
  withdrawFormSubmitFailed,
  withdrawFormSubmitSuccess,
} from '../../actions/Dialogs';
import { userWalletUpdate } from '../../actions/Users';
import fetchApi from '../../../utils/fetchApi';
import { RootState } from '../../index';
import { parseForHookForm } from '../../../utils/parseResponseErrors';
import { ResponseErrors } from '../../../utils/types';


function* withdrawRequest(
  { payload: { payload, cbError, cbCompleted } }: PayloadAction<
    { payload: string | FormData; cbError: FormContextValues['setError']; cbCompleted: () => void }
  >,
): SagaIterator {
  try {
    // @ts-ignore
    const { Users: { user: { id } } }: RootState = yield select();
    const { response, success, status } = yield call(
      fetchApi,
      {
        method: 'POST',
        payloadUrl: `/withdraw/request/${id}/`,
        payload,
      },
    );
    cbCompleted();
    if (success) {
      if (!response) {
        yield put(withdrawFormSubmitSuccess());
      } else {
        // @ts-ignore
        yield put(withdrawFormSubmitSuccess(response));
        // @ts-ignore
        yield put(userWalletUpdate(response));
        // dispatch(getcourseDialogToggleHide());
      }
    } else {
      parseForHookForm<ResponseErrors>(response, cbError);
      if (status === 503) {
        throw new Error('Сервис недоступен');
      }
    }
  } catch ({ message }) {
    yield put(withdrawFormSubmitFailed({ message }));
  }
}

export default function* (): SagaIterator {
  yield takeEvery(withdrawFormSubmit.type, withdrawRequest);
}
