import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { snakeCase } from 'lodash';

import { contactDialogToggleHide, contactFormSubmit } from 'store/actions/Dialogs';
import { RootState } from 'store';
import styles from './styles';
import fields, { additionalFields } from './fields';
import SelectField from './Subcomponents/SelectField';

const useStyles = makeStyles(styles, { name: 'ContactDialog' });

const ContactDialog: FC = (props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const { isOpen } = useSelector(({ Dialogs: { contact } }: RootState) => contact);
  const {
    register, control, handleSubmit, setError, errors,
  } = useForm();

  const handleClose = useCallback(
    () => {
      dispatch(contactDialogToggleHide());
    },
    [dispatch],
  );
  const onSubmit = useCallback(
    (data) => {
      const payload: {[key: string]: unknown} = {};
      Object.entries(data).forEach(([key, value]) => {
        payload[snakeCase(key)] = value;
      });

      dispatch(contactFormSubmit({
        payload: JSON.stringify(payload),
        cbError: setError,
      }));
    },
    [setError, dispatch],
  );

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">
          {t('add_contact')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('fill_contact_data')}
          </DialogContentText>
          {fields.map(({
            key, type, name, label, id, fullWidth, validateArgs, ...other
          }) => {
            const error = errors[key];
            return (
              <FormControl key={key} margin="normal" fullWidth={fullWidth}>
                <InputLabel htmlFor={id}>{t(label)}</InputLabel>
                { type === 'select' ? (
                  <>
                    <SelectField id={id} name={name} control={control} choicesUrl={other.choicesUrl ?? ''} error={error} />
                    {error && (
                      <FormHelperText error>
                        {error.message}
                      </FormHelperText>
                    )}
                  </>
                ) : (
                  <>
                    <Input
                      id={id}
                      type={type}
                      name={name}
                      {...other}
                      error={!!error}
                      inputRef={register(validateArgs)}
                    />
                    {error && (
                      <FormHelperText error>
                        {error.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              </FormControl>
            );
          })}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {t('additional_contact_data')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.panelDetalis}>
              {additionalFields.map(({
                key, label, id, fullWidth, validateArgs, ...other
              }) => {
                const error = errors[key];
                return (
                  <FormControl key={key} margin="normal" fullWidth={fullWidth}>
                    <InputLabel htmlFor={id}>{label}</InputLabel>
                    <Input
                      id={id}
                      {...other}
                      error={!!error}
                      inputRef={register(validateArgs)}
                    />
                    {error && (
                      <FormHelperText error>
                        {error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button color="primary" type="submit">
            {t('save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(ContactDialog);
