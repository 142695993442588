import {
  call, put, takeEvery,
} from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from '@redux-saga/core';

import fetchApi, { fetchApiFiles } from '../../../utils/fetchApi';
import {
  activationFormSubmit,
  activationFormSubmitSuccess,
  activationSuccess,
} from '../../actions/Dialogs';
import {
  profileUpdateSuccess,
} from '../../actions/Users';

function* activationSubmit(
  { payload: { payload } }: PayloadAction<
    { payload: string | FormData }
    >,
): SagaIterator {
  try {
    const { response, success, status } = yield call(
      typeof payload === 'string' ? fetchApi : fetchApiFiles,
      {
        method: 'GET',
        payloadUrl: '/accounts/users/partnership_activation/',
        payload,
      },
    );
    if (success && status) {
      if (!response) {
        yield put(activationFormSubmitSuccess());
      } else {
        yield put(activationSuccess());
        yield put(profileUpdateSuccess(response));
      }
    }
  } catch ({ message }) {
    yield message;
  }
}

export default function* (): SagaIterator {
  yield takeEvery(activationFormSubmit.type, activationSubmit);
}
