
import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import { snackToggle, snackToggleHide, snackToggleShow } from '../actions/ThrowSnack';
import { profileUpdateEmailSuccess, profileUpdateSuccess, linkCopiedSuccess } from '../actions/Users';
import { ThrowSnackState } from '../interfaces/ThrowSnack';
import { signInFailed } from '../actions/Auth';
import defaultReducerFunc from '../../utils/defaultReducerFunc';
import {
  getcourseFormSubmitFailed,
  getcourseFormSubmitSuccess,
  withdrawFormSubmitFailed,
  withdrawFormSubmitSuccess,
  subscriptionFormSubmitFailed,
  paymentFormSubmitFailed,
  subscriptionSuccess,
} from '../actions/Dialogs';
import i18next from '../../services/i18n';


const INITIAL_STATE: ThrowSnackState = {
  type: 'success',
  isOpen: false,
  title: '',
  text: '',
};

type Builder = ActionReducerMapBuilder<ThrowSnackState>
const actionsMap = (builder: Builder): Builder => builder
  .addCase(
    snackToggle,
    defaultReducerFunc,
  )
  .addCase(
    snackToggleShow,
    (state, { payload }) => ({
      ...state,
      ...payload,
      isOpen: true,
    }),
  )
  .addCase(
    snackToggleHide,
    (state) => ({
      ...state,
      isOpen: false,
    }),
  )
  .addCase(
    signInFailed,
    (state, { payload }) => ({
      ...state,
      type: 'warning',
      isOpen: true,
      text: payload.message,
      title: i18next.t('login-error'),
    }),
  )
  .addCase(
    profileUpdateSuccess,
    (state) => ({
      ...state,
      ...INITIAL_STATE,
      isOpen: true,
      text: i18next.t('information-saved'),
    }),
  )
  .addCase(
    linkCopiedSuccess,
    (state) => ({
      ...state,
      ...INITIAL_STATE,
      isOpen: true,
      text: i18next.t('link_copied'),
    }),
  )
  .addCase(
    profileUpdateEmailSuccess,
    (state) => ({
      ...state,
      ...INITIAL_STATE,
      isOpen: true,
      text: i18next.t('email-changed-tooltip'),
      title: i18next.t('attention'),
    }),
  )
  .addCase(
    withdrawFormSubmitSuccess,
    (state) => ({
      ...state,
      ...INITIAL_STATE,
      isOpen: true,
      text: i18next.t('withdrawal-request-sent'),
      title: i18next.t('Completed!'),
    }),
  )
  .addCase(
    withdrawFormSubmitFailed,
    (
      state,
      { payload },
    ) => ({
      ...state,
      ...INITIAL_STATE,
      isOpen: true,
      type: 'error',
      text: payload.message,
      title: i18next.t('Error!'),
    }),
  )
  .addCase(
    getcourseFormSubmitSuccess,
    (state) => ({
      ...state,
      ...INITIAL_STATE,
      isOpen: true,
      text: i18next.t('getcource-access-granted'),
      title: i18next.t('Completed!'),
    }),
  )
  .addCase(
    getcourseFormSubmitFailed,
    (
      state,
      { payload },
    ) => ({
      ...state,
      ...INITIAL_STATE,
      isOpen: true,
      type: 'error',
      text: payload.message,
      title: i18next.t('Error!'),
    }),
  )
  .addCase(
    subscriptionFormSubmitFailed,
    (
      state,
      { payload },
    ) => ({
      ...state,
      ...INITIAL_STATE,
      isOpen: true,
      type: 'error',
      text: payload.message,
      title: i18next.t('Error!'),
    }),
  )
  .addCase(
    paymentFormSubmitFailed,
    (
      state,
      { payload },
    ) => ({
      ...state,
      ...INITIAL_STATE,
      isOpen: true,
      type: 'error',
      text: payload.message,
      title: i18next.t('Error!'),
    }),
  )
  .addCase(
    subscriptionSuccess,
    (
      state,
    ) => ({
      ...state,
      ...INITIAL_STATE,
      isOpen: true,
      type: 'success',
      text: i18next.t('Subscription purchased'),
      title: '',
    }),
  );


export default createReducer(
  INITIAL_STATE,
  actionsMap,
);
