import { createAction } from '@reduxjs/toolkit';
import {
  DIALOGS_ACTIVATION_FORM_SUBMIT,
  DIALOGS_ACTIVATION_FORM_SUBMIT_SUCCESS,
  DIALOGS_ACTIVATION_TOGGLE,
  DIALOGS_ACTIVATION_TOGGLE_HIDE,
  DIALOGS_ACTIVATION_TOGGLE_SHOW,
  DIALOGS_ACTIVATION_SUCCESS,
} from '../../actionTypes';


export const activationFormSubmit = createAction<
  unknown,
  typeof DIALOGS_ACTIVATION_FORM_SUBMIT
  >(DIALOGS_ACTIVATION_FORM_SUBMIT);

export const activationFormSubmitSuccess = createAction<
  void,
  typeof DIALOGS_ACTIVATION_FORM_SUBMIT_SUCCESS
  >(DIALOGS_ACTIVATION_FORM_SUBMIT_SUCCESS);

export const activationDialogToggle = createAction<
  void,
  typeof DIALOGS_ACTIVATION_TOGGLE
  >(DIALOGS_ACTIVATION_TOGGLE);

export const activationDialogToggleShow = createAction<
  void,
  typeof DIALOGS_ACTIVATION_TOGGLE_SHOW
  >(DIALOGS_ACTIVATION_TOGGLE_SHOW);

export const activationDialogToggleHide = createAction<
  void,
  typeof DIALOGS_ACTIVATION_TOGGLE_HIDE
  >(DIALOGS_ACTIVATION_TOGGLE_HIDE);

export const activationSuccess = createAction<
  void,
  typeof DIALOGS_ACTIVATION_SUCCESS
  >(DIALOGS_ACTIVATION_SUCCESS);
