import React, {
  FC, Suspense, useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CssBaseline,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  withStyles,
  withWidth,
  isWidthUp,
} from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMount, useLocation } from 'react-use';
import { subscriptionDialogToggleShow } from 'store/actions/Dialogs';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { insideRoutes } from '../../routes';
import Page404 from '../../views/Page404';
import { RootState } from '../../store';
import { Props } from './types';
import WithdrawDialog from '../WithdrawDialog';
import GetcourseDialog from '../GetcourseDialog';
import SubscriptionDialog from '../SubscriptionDialog';
import ActivationDialog from '../ActivationDialog';
import PaymentDialog from '../PaymentDialog';
import ContactDialog from '../ContactDialog';
import styles from './styles';

const GlobalStyle = withStyles((theme: Theme) => ({
  '@global': {
    // eslint-disable-next-line
    'body': {
      background: theme.body.background,
    },

    '.MuiCardHeader-action .MuiIconButton-root': {
      padding: 4,
      width: 28,
      height: 28,
    },
  },
}))(() => null);

const useStyles = makeStyles(styles, { name: 'DashboardLayout' });

const DashboardLayout: FC<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { width } = props;

  const { token } = useSelector(({ Auth }: RootState) => Auth);
  const { user } = useSelector(({ Users }: RootState) => Users);
  const {
    avatar_165: avatarUrl,
    first_name: firstName = '',
    last_name: lastName,
  } = user || {};

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [setMobileOpen, mobileOpen]);

  const routerRender = useCallback(
    (Component) => (): JSX.Element => {
      if (!token) {
        return <Redirect to="/sign-in" />;
      }

      return (
        <Suspense fallback={<div />}>
          <Component />
        </Suspense>
      );
    },
    [token],
  );
  const { search } = useLocation();
  useMount(
    () => {
      if (search) {
        const params = new URLSearchParams(search);
        if (params.has('subscription')) {
          dispatch(subscriptionDialogToggleShow());
        }
      }
    },
  );

  return (
    <>
      <Box className={classes.root}>
        <CssBaseline />
        <GlobalStyle />
        <Box className={classes.drawer}>
          <Hidden mdUp implementation="js">
            <Sidebar
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              onClick={handleDrawerToggle}
              firstName={firstName}
              lastName={lastName}
            />
          </Hidden>
          <Hidden smDown implementation="css">
            <Sidebar
              variant="permanent"
              firstName={firstName}
              lastName={lastName}
            />
          </Hidden>
        </Box>
        <Box className={classes.appContent}>
          <Header
            avatarUrl={avatarUrl}
            onDrawerToggle={handleDrawerToggle}
          />
          <Box p={isWidthUp('lg', width) ? 10 : 5} className={classes.mainContent}>
            <Hidden mdUp>
              <Grid justify="space-between" container spacing={6}>
                <Switch>
                  {insideRoutes.map(({
                    headerComponent: Component, path, exact, title,
                  }) => (
                    <Route
                      key={path}
                      path={path}
                      exact={exact}
                    >
                      <Suspense fallback={<div />}>
                        <Component firstName={firstName} title={t(title)} />
                      </Suspense>
                    </Route>
                  ))}
                </Switch>
              </Grid>
              <Divider className={classes.divider} />
            </Hidden>
            <Switch>
              {insideRoutes.map(({ path, component, exact }) => (
                <Route
                  exact={exact}
                  path={path}
                  key={path}
                  render={routerRender(component)}
                />
              ))}
              <Route
                component={Page404}
              />
            </Switch>
          </Box>
        </Box>
      </Box>
      <WithdrawDialog />
      <GetcourseDialog />
      <SubscriptionDialog />
      <ActivationDialog />
      <PaymentDialog />
      <ContactDialog />
    </>
  );
};


export default withWidth()(DashboardLayout);
