/* eslint-disable @typescript-eslint/explicit-function-return-type */
import defaultReducerFunc from '../../../utils/defaultReducerFunc';
import {
  activationDialogToggle,
  activationDialogToggleHide,
  activationDialogToggleShow,
  activationFormSubmitSuccess,
} from '../../actions/Dialogs';
import { ActivationDialogState } from '../../interfaces/Dialogs';


const INITIAL_STATE: ActivationDialogState = {
  isOpen: false,
};

const actionsMap = [
  {
    action: activationDialogToggle,
    reducer: defaultReducerFunc,
  },
  {
    action: activationDialogToggleShow,
    reducer: (state: ActivationDialogState) => ({
      ...state,
      isOpen: true,
    }),
  },
  {
    action: activationDialogToggleHide,
    reducer: (state: ActivationDialogState) => ({
      ...state,
      isOpen: false,
    }),
  },
  {
    action: activationFormSubmitSuccess,
    reducer: (state: ActivationDialogState) => ({
      ...state,
      isOpen: false,
    }),
  },
];

export default { INITIAL_STATE, actionsMap };
