import React, {
  FC, memo, useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Chip, Collapse, ListItem, ListItemText, makeStyles,
} from '@material-ui/core';
import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';

import { RootState } from '../../../store';
import styles from '../styles';
import { CategoryProps } from '../types';
import SidebarLink from './SidebarLink';


const useStyles = makeStyles(styles, { name: 'SidebarCategory' });


const SidebarCategory: FC<CategoryProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpen, setOpen] = React.useState(false);
  const classes = useStyles(props);
  const {
    name,
    icon: Icon,
    badge = '',
    path,
    activeClassName,
    getChildren,
    getChildrenAction,
    onClick,
    ...rest
  } = props;

  let { children } = props;

  const reduxState = useSelector((state: RootState) => state);
  const { categories } = useSelector(({ ProductCategories }: RootState) => ProductCategories);

  const handleToggle = useCallback(
    () => {
      setOpen(!isOpen);
    },
    [isOpen, setOpen],
  );

  useMount(() => {
    if (getChildrenAction) {
      getChildrenAction(dispatch);
    }
  });

  useEffect(
    () => {
      if (getChildren) {
        children = getChildren(reduxState);
      }
    },
    [reduxState, getChildren, children],
  );

  const renderLink = React.useMemo(
    () => React.forwardRef<never, Omit<NavLinkProps, 'to'>>((itemProps, ref) => (
      <RouterNavLink to={path} ref={ref} exact {...itemProps} />
    )),
    [path],
  );

  return (
    <>
      {children ? (
        <>
          <ListItem
            className={classes.category}
            {...rest}
            component={renderLink}
            onClick={handleToggle}
            activeClassName={activeClassName}
          >
            <Icon />
            <ListItemText className={classes.categoryText}>{name}</ListItemText>
            {children && (
              isOpen ? (
                <ExpandLess className={classes.categoryIcon} />
              ) : (
                <ExpandMore className={classes.categoryIcon} />
              )
            )}
            {!!badge && <Chip label={badge} className={classes.linkBadge} />}
          </ListItem>
          <Collapse
            in={isOpen}
            unmountOnExit
          >
            <>
              <SidebarLink
                activeClassName={activeClassName}
                key="purchased"
                name={t('purchased')}
                path="/products/purchased"
                /* @ts-ignore */
                onClick={onClick}
              />
              {children && categories.map((item) => (
                <SidebarLink
                  activeClassName={activeClassName}
                  key={item.pk.toString()}
                  name={item.title}
                  path={`/products/${item.slug}`}
                  /* @ts-ignore */
                  onClick={onClick}
                />
              ))}
            </>
          </Collapse>
        </>
      ) : (
        <ListItem className={classes.category} {...rest} component={renderLink}>
          <Icon />
          <ListItemText className={classes.categoryText}>{name}</ListItemText>
          {!!badge && <Chip label={badge} className={classes.linkBadge} />}
        </ListItem>
      )}
    </>
  );
};

export default memo(SidebarCategory);
