import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  button: {
    marginTop: theme.spacing(2),
  },
  wrapper: {
    padding: theme.spacing(6),
    textAlign: 'center',
    background: 'transparent',

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10),
    },
  },
});
