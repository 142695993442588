import { createAction } from '@reduxjs/toolkit';
import {
  DIALOGS_SUBSCRIPTION_FORM_SUBMIT,
  DIALOGS_SUBSCRIPTION_FORM_SUBMIT_FAILED,
  DIALOGS_SUBSCRIPTION_FORM_SUBMIT_SUCCESS,
  DIALOGS_SUBSCRIPTION_TOGGLE,
  DIALOGS_SUBSCRIPTION_TOGGLE_HIDE,
  DIALOGS_SUBSCRIPTION_TOGGLE_SHOW,
  DIALOGS_SUBSCRIPTION_SUCCESS,
} from '../../actionTypes';


export const subscriptionFormSubmit = createAction<
  unknown,
  typeof DIALOGS_SUBSCRIPTION_FORM_SUBMIT
  >(DIALOGS_SUBSCRIPTION_FORM_SUBMIT);

export const subscriptionFormSubmitSuccess = createAction<
  void,
  typeof DIALOGS_SUBSCRIPTION_FORM_SUBMIT_SUCCESS
  >(DIALOGS_SUBSCRIPTION_FORM_SUBMIT_SUCCESS);

export type subscriptionFormSubmitFailedPayload = { message: string }
export const subscriptionFormSubmitFailed = createAction<
  subscriptionFormSubmitFailedPayload,
  typeof DIALOGS_SUBSCRIPTION_FORM_SUBMIT_FAILED
  >(DIALOGS_SUBSCRIPTION_FORM_SUBMIT_FAILED);

export const subscriptionDialogToggle = createAction<
  void,
  typeof DIALOGS_SUBSCRIPTION_TOGGLE
  >(DIALOGS_SUBSCRIPTION_TOGGLE);

export const subscriptionDialogToggleShow = createAction<
  void,
  typeof DIALOGS_SUBSCRIPTION_TOGGLE_SHOW
  >(DIALOGS_SUBSCRIPTION_TOGGLE_SHOW);

export const subscriptionDialogToggleHide = createAction<
  void,
  typeof DIALOGS_SUBSCRIPTION_TOGGLE_HIDE
  >(DIALOGS_SUBSCRIPTION_TOGGLE_HIDE);

export const subscriptionSuccess = createAction<
  void,
  typeof DIALOGS_SUBSCRIPTION_SUCCESS
  >(DIALOGS_SUBSCRIPTION_SUCCESS);
