/* eslint-disable @typescript-eslint/explicit-function-return-type */
import defaultReducerFunc from '../../../utils/defaultReducerFunc';
import {
  subscriptionDialogToggle,
  subscriptionDialogToggleHide,
  subscriptionDialogToggleShow,
  subscriptionFormSubmitSuccess,
} from '../../actions/Dialogs';
import { SubscriptionDialogState } from '../../interfaces/Dialogs';


const INITIAL_STATE: SubscriptionDialogState = {
  isOpen: false,
};

const actionsMap = [
  {
    action: subscriptionDialogToggle,
    reducer: defaultReducerFunc,
  },
  {
    action: subscriptionDialogToggleShow,
    reducer: (state: SubscriptionDialogState) => ({
      ...state,
      isOpen: true,
    }),
  },
  {
    action: subscriptionDialogToggleHide,
    reducer: (state: SubscriptionDialogState) => ({
      ...state,
      isOpen: false,
    }),
  },
  {
    action: subscriptionFormSubmitSuccess,
    reducer: (state: SubscriptionDialogState) => ({
      ...state,
      isOpen: false,
    }),
  },
];

export default { INITIAL_STATE, actionsMap };
