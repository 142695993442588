import {
  call, put, select, takeEvery,
} from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { FormContextValues } from 'react-hook-form';
import { SagaIterator } from '@redux-saga/core';

import fetchApi, { fetchApiFiles } from '../../../utils/fetchApi';
import {
  subscriptionFormSubmit,
  subscriptionFormSubmitFailed,
  subscriptionFormSubmitSuccess,
  subscriptionSuccess,
} from '../../actions/Dialogs';
import { parseForHookForm } from '../../../utils/parseResponseErrors';
import { ResponseErrors } from '../../../utils/types';


function* subscriptionSubmit(
  { payload: { payload, cbError, cbCompleted } }: PayloadAction<
    { payload: string | FormData; cbError: FormContextValues['setError']; cbCompleted: () => void }
    >,
): SagaIterator {
  try {
    const { Users: { user: { id } } } = yield select();
    const { response, success, status } = yield call(
      typeof payload === 'string' ? fetchApi : fetchApiFiles,
      {
        method: 'POST',
        payloadUrl: `/subscription/${id}/buy/`,
        payload,
      },
    );
    cbCompleted();
    if (success) {
      if (!response) {
        yield put(subscriptionFormSubmitSuccess());
      } else if (response.paid) {
        yield put(subscriptionSuccess());
        // @ts-ignore
        yield put(subscriptionFormSubmitSuccess(response));
      } else {
        window.location.href = response.url;
        // @ts-ignore
        yield put(subscriptionFormSubmitSuccess(response));
      }
    } else {
      parseForHookForm<ResponseErrors>(response, cbError);
      yield put(subscriptionFormSubmitFailed({ message: response.error }));
      if (status === 503) {
        throw new Error('Сервис недоступен');
      }
    }
  } catch ({ message }) {
    yield put(subscriptionFormSubmitFailed({ message }));
  }
}

export default function* (): SagaIterator {
  yield takeEvery(subscriptionFormSubmit.type, subscriptionSubmit);
}
