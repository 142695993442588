import {
  call, put, select, takeEvery,
} from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { FormContextValues } from 'react-hook-form';
import { SagaIterator } from '@redux-saga/core';

import fetchApi, { fetchApiFiles } from '../../utils/fetchApi';
import {
  paymentFormSubmit,
  paymentFormSubmitFailed,
  paymentFormSubmitSuccess,
} from '../actions/Dialogs';
import { parseForHookForm } from '../../utils/parseResponseErrors';
import { ResponseErrors } from '../../utils/types';


function* paymentSubmit(
  { payload: { payload, cbError, cbCompleted } }: PayloadAction<
    { payload: string | FormData; cbError: FormContextValues['setError']; cbCompleted: () => void }
    >,
): SagaIterator {
  try {
    const { PaymentDialog: { productPk: id } } = yield select();
    const { response, success, status } = yield call(
      typeof payload === 'string' ? fetchApi : fetchApiFiles,
      {
        method: 'POST',
        payloadUrl: `/payments/product/${id}/buy/`,
        payload,
      },
    );
    cbCompleted();
    if (success) {
      if (!response) {
        yield put(paymentFormSubmitSuccess());
      } else {
        window.location.href = response.url;
        // @ts-ignore
        yield put(paymentFormSubmitSuccess(response));
      }
    } else {
      yield put(paymentFormSubmitFailed({ message: response.error }));
      parseForHookForm<ResponseErrors>(response, cbError);
      if (status === 503) {
        throw new Error('Сервис недоступен');
      }
    }
  } catch ({ message }) {
    yield put(paymentFormSubmitFailed({ message }));
  }
}

export default function* (): SagaIterator {
  yield takeEvery(paymentFormSubmit.type, paymentSubmit);
}
