import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@material-ui/core';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useLocation, useMount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionDialogToggleShow } from 'store/actions/Dialogs';
// @ts-ignore
import { NotificationContainer } from 'react-notifications';

import createMyTheme from './theme';
import DashboardLayout from './modules/DashboardLayout';
import AuthLayout from './modules/AuthLayout';
import { signInByBot } from './store/actions/Auth';
import AlertSnackbar from './modules/AlertSnackbar';
import { insideRoutesPaths, outsideRoutesPaths } from './routes';
import Page404 from './views/Page404';
import lightVariant from './theme/variants';
import { RootState } from './store';

const muiTheme = createMyTheme(lightVariant);

const App: FC = () => {
  const { user } = useSelector(({ Users }: RootState) => Users);
  const dispatch = useDispatch();
  const history = useHistory();
  const { i18n } = useTranslation();

  const { search, pathname } = useLocation();
  useMount(
    () => {
      if (search) {
        const params = new URLSearchParams(search);
        if (params.has('token')) {
          dispatch(signInByBot(JSON.stringify({ token: params.get('token') })));
          if (pathname) {
            setTimeout(() => { history.push(pathname); }, 1000);
          }
        }
        if (params.has('subscription')) {
          dispatch(subscriptionDialogToggleShow());
        }
      }
    },
  );
  useEffect(
    () => {
      console.log('navigator lang', navigator.language.slice(0, 2));
      if (user.language) {
        i18n.changeLanguage(user.language);
      } else if (['en', 'ru', 'uk'].includes(navigator.language.slice(0, 2))) {
        i18n.changeLanguage(navigator.language.slice(0, 2));
      }
    },
    [user],
  );

  return (
    <ThemeProvider theme={muiTheme}>
      <Switch>
        <Route path={outsideRoutesPaths} exact>
          <AuthLayout />
        </Route>
        <Route path={insideRoutesPaths} exact>
          <DashboardLayout />
        </Route>
        <Route
          component={Page404}
        />
      </Switch>
      <AlertSnackbar />
      <NotificationContainer />
    </ThemeProvider>
  );
};


export default App;
