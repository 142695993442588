import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  panelDetalis: {
    display: 'block',
  },
});
