import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';

import { PaymentDialogState } from '../interfaces/Dialogs';
import defaultReducerFunc from '../../utils/defaultReducerFunc';
import {
  paymentDialogToggle,
  paymentDialogToggleHide,
  paymentDialogToggleShow,
  paymentFormSubmitSuccess,
} from '../actions/Dialogs';


const INITIAL_STATE: PaymentDialogState = {
  isOpen: false,
  productPk: 0,
  amount: 0,
};

type Builder = ActionReducerMapBuilder<PaymentDialogState>
const actionsMap = (builder: Builder): Builder => builder
  .addCase(
    paymentDialogToggle,
    defaultReducerFunc,
  )
  .addCase(
    paymentFormSubmitSuccess,
    (state) => ({
      ...state,
    }),
  )
  .addCase(
    paymentDialogToggleHide,
    (state) => ({
      ...state,
      isOpen: false,
    }),
  )
  .addCase(
    paymentDialogToggleShow,
    (state, { payload }) => ({
      ...state,
      ...payload,
      isOpen: true,
    }),
  );

export default createReducer(
  INITIAL_STATE,
  actionsMap,
);
