/* eslint-disable max-len */
import { createAction } from '@reduxjs/toolkit';
import { FormContextValues } from 'react-hook-form';
import {
  USERS_PROFILE_UPDATE,
  USERS_PROFILE_UPDATE_EMAIL_SUCCESS,
  USERS_PROFILE_UPDATE_FAILED,
  USERS_PROFILE_UPDATE_SUCCESS,
  USERS_WALLET_UPDATE,
  USERS_LINK_COPIED_SUCCESS,
} from '../actionTypes';
import { User, Wallet } from '../interfaces/Users';

export type profileUpdatePayload = {
  payload: string | FormData;
  cbError: FormContextValues['setError'];
}
export const profileUpdate = createAction<
  profileUpdatePayload,
  typeof USERS_PROFILE_UPDATE
>(USERS_PROFILE_UPDATE);

export const profileUpdateSuccess = createAction<
  User,
  typeof USERS_PROFILE_UPDATE_SUCCESS
>(USERS_PROFILE_UPDATE_SUCCESS);

export const linkCopiedSuccess = createAction<
  void,
  typeof USERS_LINK_COPIED_SUCCESS
>(USERS_LINK_COPIED_SUCCESS);

export type profileUpdateFailedPayload = { message: string }
export const profileUpdateFailed = createAction<
  profileUpdateFailedPayload,
  typeof USERS_PROFILE_UPDATE_FAILED
>(USERS_PROFILE_UPDATE_FAILED);

export const profileUpdateEmailSuccess = createAction<
  void,
  typeof USERS_PROFILE_UPDATE_EMAIL_SUCCESS
>(USERS_PROFILE_UPDATE_EMAIL_SUCCESS);

export const userWalletUpdate = createAction<
  Wallet,
  typeof USERS_WALLET_UPDATE
>(USERS_WALLET_UPDATE);
