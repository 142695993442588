import { createStyles, Theme } from '@material-ui/core';


export default (theme: Theme) => createStyles({
  packLabel: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  packLabelText: {
    width: '100%',
    verticalAlign: 'super',
  },
  paySystems: {
    display: 'inline-block',
  },
  packs: {
    display: 'flex',
    '& span': {
      verticalAlign: 'super',
    },
  },
  productOldPrice: {
    verticalAlign: 'super',
    textDecoration: 'line-through',
    display: 'contents',
    fontSize: '2rem',
  },
  productPrice: {
    verticalAlign: 'super',
    display: 'contents',
    fontWeight: 600,
    fontSize: '2rem',
    textAlign: 'center',
  },
});
