/* eslint-disable max-len */
import { createAction } from '@reduxjs/toolkit';

import {
  AUTH_SIGN_IN,
  AUTH_SIGN_UP,
  AUTH_SIGN_IN_BY_BOT,
  AUTH_SIGN_IN_FAILED,
  AUTH_SIGN_IN_SUCCESS,
  AUTH_SIGN_OUT,
  AUTH_TOKEN_REFRESH,
  AUTH_TOKEN_REFRESH_FAILED,
  AUTH_TOKEN_REFRESH_SUCCESS,
} from '../actionTypes';
import { User } from '../interfaces/Users';


export const tokenRefresh = createAction<
  string,
  typeof AUTH_TOKEN_REFRESH
>(AUTH_TOKEN_REFRESH);

export type tokenRefreshSuccessPayload = {
  token: string;
  user: User;
}
export const tokenRefreshSuccess = createAction<
  tokenRefreshSuccessPayload,
  typeof AUTH_TOKEN_REFRESH_SUCCESS
>(AUTH_TOKEN_REFRESH_SUCCESS);

export const tokenRefreshFailed = createAction<
  string,
  typeof AUTH_TOKEN_REFRESH_FAILED
>(AUTH_TOKEN_REFRESH_FAILED);

export const signIn = createAction<string, typeof AUTH_SIGN_IN>(AUTH_SIGN_IN);
export const signUp = createAction<string, typeof AUTH_SIGN_UP>(AUTH_SIGN_UP);

export const signInByBot = createAction<string, typeof AUTH_SIGN_IN_BY_BOT>(AUTH_SIGN_IN_BY_BOT);

export type signInSuccessPayload = {
  token: string;
  user: User;
}
export const signInSuccess = createAction<
  signInSuccessPayload,
  typeof AUTH_SIGN_IN_SUCCESS
>(AUTH_SIGN_IN_SUCCESS);

export type signInFailedPayload = { message: string }
export const signInFailed = createAction<
  signInFailedPayload,
  typeof AUTH_SIGN_IN_FAILED
>(AUTH_SIGN_IN_FAILED);

export const signOut = createAction<void, typeof AUTH_SIGN_OUT>(AUTH_SIGN_OUT);
