import {
  call,
  put,
  takeEvery,
  select,
} from '@redux-saga/core/effects';
import { REHYDRATE } from 'redux-persist';
import { SagaIterator } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';

import fetchApi from '../../utils/fetchApi';
import {
  signInFailed,
  signInSuccess,
  tokenRefresh as tokenRefreshAction,
  tokenRefreshFailed,
  tokenRefreshSuccess,
  signUp as signUpAction,
  signIn as signInAction,
  signOut as signOutAction,
  signInByBot as signInByBotAction,
} from '../actions/Auth';


function* signIn({ payload: actionPayload }: PayloadAction<string>): SagaIterator {
  const { Auth: { token: tokenState } } = yield select();
  if (tokenState !== '') return;
  try {
    const { response, success, status } = yield call(fetchApi, {
      method: 'POST',
      payloadUrl: '/token-auth/',
      payload: actionPayload,
    });
    if (success) {
      yield put(signInSuccess(response));
      return;
    }

    let message;
    switch (status) {
      case 400: {
        const errors: string[] = [];
        const responseErrors: Array<string[]> = Object.values(response);
        responseErrors.forEach((value) => errors.push(value.join('\n')));
        message = errors.join('\n');
        break;
      }
      case 401: {
        message = response;
        break;
      }
      default:
        message = 'Что-то пошло не так. Попробуйте еще раз через несколько минут или свяжитесь с администратором.';
    }
    yield put(signInFailed({ message }));
  } catch (e) {
    yield put(signInFailed({ message: e.message }));
  }
}

function* signUp({ payload: actionPayload }: PayloadAction<string>): SagaIterator {
  const { Auth: { token: tokenState } } = yield select();
  if (tokenState !== '') return;
  try {
    const { response, success, status } = yield call(fetchApi, {
      method: 'POST',
      payloadUrl: '/accounts/register/',
      payload: actionPayload,
    });
    if (success) {
      yield put(signInSuccess(response));
      return;
    }

    let message;
    switch (status) {
      case 400: {
        const errors: string[] = [];
        const responseErrors: Array<string[]> = Object.values(response);
        responseErrors.forEach((value) => errors.push(value.join('\n')));
        message = errors.join('\n');
        break;
      }
      case 401: {
        message = response;
        break;
      }
      default:
        message = 'Что-то пошло не так. Попробуйте еще раз через несколько минут или свяжитесь с администратором.';
    }
    yield put(signInFailed({ message }));
  } catch (e) {
    yield put(signInFailed({ message: e.message }));
  }
}

function* signInByBot({ payload: actionPayload }: PayloadAction<string>): SagaIterator {
  const { Auth: { token: tokenState } } = yield select();
  if (tokenState !== '') return;
  try {
    const { response, status, success } = yield call(fetchApi, {
      method: 'POST',
      payloadUrl: '/token-auth-bot/',
      payload: actionPayload,
    });

    if (success) {
      yield put(signInSuccess(response));
      return;
    }
    let message;

    switch (status) {
      case 400: {
        const errors: string[] = [];
        const responseErrors: Array<string[]> = Object.values(response);
        responseErrors.forEach((err) => errors.push(err.join('\n')));
        message = errors.join('\n');
        break;
      }
      case 401: {
        message = response;
        break;
      }
      default:
        message = 'Что-то пошло не так. Попробуйте еще раз через несколько минут или свяжитесь с администратором.';
    }
    yield put(signInFailed({ message }));
  } catch (e) {
    yield put(signInFailed({ message: e.message }));
  }
}

function* signOut(): SagaIterator {
  try {
    yield call(fetchApi, {
      payloadUrl: '/accounts/logout/',
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e.message);
  }
}

function* tokenRefresh({ payload }: PayloadAction<string>): SagaIterator {
  try {
    const { response: { token, user }, status } = yield call(fetchApi, {
      method: 'POST',
      payloadUrl: '/token-refresh/',
      payload: JSON.stringify({ token: payload }),
    });
    if (status === 200) {
      yield put(tokenRefreshSuccess({
        token,
        user,
      }));
    } else {
      throw new Error('Токен истек');
    }
  } catch (e) {
    yield put(tokenRefreshFailed(e.message));
    yield put(signOutAction());
  }
}

function* rehydrate({ payload }: PayloadAction<{token: string}>): SagaIterator {
  if (payload && payload.token) {
    try {
      yield put(tokenRefreshAction(payload.token));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }
}

export default function* (): SagaIterator {
  yield takeEvery(signInAction.type, signIn);
  yield takeEvery(signUpAction.type, signUp);
  yield takeEvery(signInByBotAction.type, signInByBot);
  yield takeEvery(signOutAction.type, signOut);
  yield takeEvery(REHYDRATE, rehydrate);
  yield takeEvery(tokenRefreshAction.type, tokenRefresh);
}
