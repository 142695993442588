/* eslint-disable @typescript-eslint/explicit-function-return-type */
import defaultReducerFunc from '../../../utils/defaultReducerFunc';
import { ContactDialogState } from '../../interfaces/Dialogs';
import {
  contactDialogToggle,
  contactDialogToggleHide,
  contactDialogToggleShow,
  contactFormSubmitSuccess,
} from '../../actions/Dialogs';


const INITIAL_STATE: ContactDialogState = {
  isOpen: false,
  contact: null,
};

const actionsMap = [
  {
    action: contactDialogToggle,
    reducer: defaultReducerFunc,
  },
  {
    action: contactDialogToggleShow,
    reducer: (state: ContactDialogState) => ({
      ...state,
      isOpen: true,
    }),
  },
  {
    action: contactDialogToggleHide,
    reducer: (state: ContactDialogState) => ({
      ...state,
      isOpen: false,
    }),
  },
  {
    action: contactFormSubmitSuccess,
    reducer: (state: ContactDialogState) => ({
      ...state,
      isOpen: false,
    }),
  },
];

export default { INITIAL_STATE, actionsMap };
