import React, {
  FC, Fragment, useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  makeStyles,
  Radio,
  RadioGroup,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import { PromoCodeResponse } from 'modules/SubscriptionDialog/types';
import PaySystemLabel from './Subcomponents/PaySystemLabel';
import styles from './styles';
import { paymentDialogToggleHide, paymentFormSubmit } from '../../store/actions/Dialogs';
import fetchApi from '../../utils/fetchApi';
import { RootState } from '../../store';
import ProductPrice from './Subcomponents/ProductPrice';

const useStyles = makeStyles(styles, { name: 'PaymentDialog' });

const PaymentDialogComponent: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const initialValidFor: number[] = [];
  const [paySystems, setPaySystems] = useState([]);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeText, setPromoCodeText] = useState('');
  const [discount, setDiscount] = React.useState(0);
  const [validFor, setValidFor] = React.useState(initialValidFor);
  const [paySystemValue, setPaySystemValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector(({ Users }: RootState) => Users);
  const {
    wallet,
  } = user || {};
  const { balance = 0 } = wallet || {};
  const {
    isOpen,
    productPk,
    amount,
  } = useSelector(({ PaymentDialog }: RootState) => PaymentDialog);
  const { register, handleSubmit, setError } = useForm();

  const handlePaySystemChange = useCallback(
    (event) => {
      setPaySystemValue(event.target.value);
    },
    [],
  );

  useMount(async () => {
    const { response, status } = await fetchApi({
      method: 'GET',
      payloadUrl: '/payments/pay-systems/',
    });
    if (status === 200) {
      // @ts-ignore
      setPaySystems(response.pay_systems);
      // @ts-ignore
      setPaySystemValue(get(response, 'pay_systems[0][0]', 'F'));
    }
  });

  const handleClose = useCallback(() => {
    dispatch(paymentDialogToggleHide());
  }, [dispatch]);

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (data) => {
      setLoading(true);
      dispatch(paymentFormSubmit({
        payload: JSON.stringify(data),
        cbError: setError,
        cbCompleted: () => setLoading(false),
      }));
    },
    [setError, dispatch],
  );

  const checkPromoCode = async (code: string): Promise<void> => {
    const payload = {
      code,
      productPk,
    };
    setPromoCodeText(t('searching_promo'));
    const { response, status } = await fetchApi<PromoCodeResponse>({
      method: 'POST',
      payloadUrl: '/promocodes/check-code/',
      payload: JSON.stringify(payload),
    });
    if (status === 200) {
      setDiscount(response.discount);
      setValidFor(response.valid_for_products);
      setPromoCodeText(response.description);
    }
  };

  const calcPrice = (pk: number, p: number, d: number): number => {
    if (validFor.length === 0) return p - ((p * d) / 100);
    if ((validFor.indexOf(Number(pk)) > -1)) return p - ((p * d) / 100);
    return p;
  };

  const promoCodehandleChange = useCallback((event) => {
    setPromoCode(event.target.value);
    checkPromoCode(event.target.value);
  }, [promoCode, setPromoCode, promoCode]);

  return (
    <div>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">
            <ProductPrice
              oldPrice={amount}
              newPrice={calcPrice(productPk, amount, discount)}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Input name="product_pk" value={productPk.toString()} type="hidden" />
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {t('PaymentMethod')}
                    </FormLabel>
                    <RadioGroup
                      aria-label="pay_system"
                      name="pay_system"
                      value={paySystemValue}
                      onChange={handlePaySystemChange}
                    >
                      {paySystems.map((
                        [id, label],
                      ) => (
                        <Fragment key={id}>
                          <FormControlLabel
                            value={id}
                            control={<Radio />}
                            label={<PaySystemLabel id={id} title={label} balance={balance} />}
                            inputRef={register()}
                          />
                        </Fragment>
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend">
                      {t('PromocodeHint')}
                    </FormLabel>
                    <Input
                      name="promocode"
                      value={promoCode}
                      onChange={promoCodehandleChange}
                      inputRef={register()}
                    />
                    <FormHelperText id="standard-weight-helper-text">{promoCodeText}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              {t('cancel')}
            </Button>
            <Button color="primary" type="submit" disabled={loading}>
              { loading && (
                <CircularProgress size="1rem" />
              )}
              {t('submit_pay')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default PaymentDialogComponent;
