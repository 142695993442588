import React, { FC, memo } from 'react';

import {
  makeStyles, ListItem, ListItemText, Chip,
} from '@material-ui/core';
import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom';
import styles from '../styles';
import { LinkProps } from '../types';


const useStyles = makeStyles(styles, { name: 'SidebarLink' });


const SidebarLink: FC<LinkProps> = (props) => {
  const classes = useStyles(props);
  const {
    name,
    badge,
    path,
    ...rest
  } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef<never, Omit<NavLinkProps, 'to'>>((itemProps, ref) => (
      <RouterNavLink to={path} ref={ref} exact {...itemProps} />
    )),
    [path],
  );

  return (
    <ListItem className={classes.categoryLink} {...rest} component={renderLink}>
      <ListItemText className={classes.categoryText}>{name}</ListItemText>
      {!!badge && <Chip label={badge} className={classes.linkBadge} />}
    </ListItem>
  );
};

SidebarLink.defaultProps = {
  badge: '',
};

export default memo(SidebarLink);
