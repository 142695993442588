import {
  call, put, select, takeEvery,
} from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { FormContextValues } from 'react-hook-form';
import { SagaIterator } from '@redux-saga/core';
import fetchApi, { fetchApiFiles } from '../../../utils/fetchApi';
import {
  getcourseFormSubmit,
  getcourseFormSubmitFailed,
  getcourseFormSubmitSuccess,
} from '../../actions/Dialogs';
import { parseForHookForm } from '../../../utils/parseResponseErrors';
import { ResponseErrors } from '../../../utils/types';


function* withdrawRequest(
  { payload: { payload, cbError, cbCompleted } }: PayloadAction<
    { payload: string | FormData; cbError: FormContextValues['setError']; cbCompleted: () => void }
    >,
): SagaIterator {
  try {
    const { Users: { user: { id } } } = yield select();
    const { response, success, status } = yield call(
      typeof payload === 'string' ? fetchApi : fetchApiFiles,
      {
        method: 'POST',
        payloadUrl: `/getcourse/${id}/register/`,
        payload,
      },
    );
    // console.log('DEBUG', response, success, status);
    cbCompleted();
    if (success) {
      if (!response) {
        yield put(getcourseFormSubmitSuccess());
      } else {
        // @ts-ignore
        yield put(getcourseFormSubmitSuccess(response));
      }
    } else if (response.message) {
      yield put(getcourseFormSubmitFailed({ message: response.message }));
    } else {
      parseForHookForm<ResponseErrors>(response, cbError);
      if (status === 503) {
        throw new Error('Сервис недоступен');
      }
    }
  } catch ({ message }) {
    // console.log('ERROR');
    yield put(getcourseFormSubmitFailed({ message }));
  }
}

export default function* (): SagaIterator {
  yield takeEvery(getcourseFormSubmit.type, withdrawRequest);
}
