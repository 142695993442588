
const fields = [
  {
    key: 'status',
    name: 'status',
    id: 'status',
    type: 'select',
    label: 'Status',
    choicesUrl: '/contacts/status/choices/',
    autoFocus: false,
    fullWidth: true,
    validateArgs: {
      required: 'Это поле обязательно',
    },
  },
  {
    key: 'category',
    name: 'category',
    id: 'category',
    type: 'select',
    label: 'Category',
    choicesUrl: '/contacts/category/choices/',
    autoFocus: false,
    fullWidth: true,
    validateArgs: {
      required: 'Это поле обязательно',
    },
  },
  {
    key: 'enviroment',
    name: 'enviroment',
    id: 'enviroment',
    type: 'select',
    label: 'Environment',
    choicesUrl: '/contacts/env/',
    autoFocus: false,
    fullWidth: true,
    validateArgs: {
      required: 'Это поле обязательно',
    },
  },
  {
    key: 'country',
    name: 'country',
    id: 'country',
    type: 'select',
    label: 'Country',
    choicesUrl: '/contacts/countries/',
    autoFocus: false,
    fullWidth: true,
    validateArgs: {
      required: 'Это поле обязательно',
    },
  },
  {
    key: 'first_name',
    name: 'first_name',
    id: 'first_name',
    type: 'text',
    label: 'FName',
    autoFocus: false,
    fullWidth: true,
    validateArgs: {
      required: 'Это поле обязательно',
    },
  },
  {
    key: 'last_name',
    name: 'last_name',
    id: 'last_name',
    type: 'text',
    label: 'LName',
    autoFocus: false,
    fullWidth: true,
    validateArgs: {
      required: 'Это поле обязательно',
    },
  },
  {
    key: 'email',
    name: 'email',
    id: 'email',
    type: 'email',
    label: 'Email',
    autoFocus: false,
    fullWidth: true,
    validateArgs: {},
  },
  {
    key: 'phone',
    name: 'phone',
    id: 'phone',
    type: 'phone',
    label: 'Phone',
    autoFocus: false,
    fullWidth: true,
    validateArgs: {},
  },
  {
    key: 'date_birth',
    name: 'date_birth',
    id: 'date_birth',
    type: 'date',
    label: 'date_birth',
    defaultValue: '01.01.1990',
    autoFocus: false,
    fullWidth: true,
    validateArgs: {},
  },
  {
    key: 'city',
    name: 'city',
    id: 'city',
    type: 'text',
    label: 'City',
    autoFocus: false,
    fullWidth: true,
    validateArgs: {},
  },
];
export const additionalFields = [
  {
    key: 'skype',
    name: 'skype',
    id: 'skype',
    type: 'text',
    label: 'Skype',
    autoFocus: true,
    fullWidth: true,
    validateArgs: {},
  },
  {
    key: 'viber',
    name: 'viber',
    id: 'viber',
    type: 'text',
    label: 'Viber',
    autoFocus: true,
    fullWidth: true,
    validateArgs: {},
  },
  {
    key: 'whatsapp',
    name: 'whatsapp',
    id: 'whatsapp',
    type: 'text',
    label: 'Whatsapp',
    autoFocus: true,
    fullWidth: true,
    validateArgs: {},
  },
  {
    key: 'telegram',
    name: 'telegram',
    id: 'telegram',
    type: 'text',
    label: 'Telegram',
    autoFocus: true,
    fullWidth: true,
    validateArgs: {},
  },
  {
    key: 'vkontakte',
    name: 'vkontakte',
    id: 'vkontakte',
    type: 'text',
    label: 'Vkontakte',
    autoFocus: true,
    fullWidth: true,
    validateArgs: {},
  },
  {
    key: 'facebook',
    name: 'facebook',
    id: 'facebook',
    type: 'text',
    label: 'Facebook',
    autoFocus: true,
    fullWidth: true,
    validateArgs: {},
  },
  {
    key: 'youtube',
    name: 'youtube',
    id: 'youtube',
    type: 'text',
    label: 'Youtube',
    autoFocus: true,
    fullWidth: true,
    validateArgs: {},
  },
  {
    key: 'instagram',
    name: 'instagram',
    id: 'instagram',
    type: 'text',
    label: 'Instagram',
    autoFocus: true,
    fullWidth: true,
    validateArgs: {},
  },
];
export default fields;
