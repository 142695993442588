import { createStyles, Theme } from '@material-ui/core';


export const drawerWidth = 260;

export default (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mainContent: {
    flex: 1,
    background: theme.body.background,
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      flex: 'none',
    },
    '.MuiPaper-root .MuiPaper-root': {
      boxShadow: 'none',
    },
  },
  divider: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
});
