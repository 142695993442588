import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Link,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { snakeCase } from 'lodash';

import {
  getcourseDialogToggleHide,
  getcourseFormSubmit,
} from '../../store/actions/Dialogs';
import { RootState } from '../../store';

const fields = [
  {
    key: 'first_name',
    name: 'first_name',
    id: 'first_name',
    type: 'text',
    autoComplete: 'first_name',
    label: 'FName',
    defaultValue: '',
    autoFocus: true,
    fullWidth: true,
    validateArgs: {
      required: 'Это поле обязательно',
    },
  },
  {
    key: 'last_name',
    name: 'last_name',
    id: 'last_name',
    type: 'text',
    autoComplete: 'last_name',
    label: 'LName',
    defaultValue: '',
    autoFocus: true,
    fullWidth: true,
    validateArgs: {
      required: 'Это поле обязательно',
    },
  },
  {
    key: 'phone',
    name: 'phone',
    id: 'phone',
    type: 'text',
    autoComplete: 'phone',
    label: 'Phone',
    defaultValue: '',
    autoFocus: true,
    fullWidth: true,
    validateArgs: {
      required: 'Это поле обязательно',
    },
  },
  {
    key: 'email',
    name: 'email',
    id: 'email',
    type: 'email',
    autoComplete: 'email',
    label: 'Email',
    defaultValue: '',
    autoFocus: true,
    fullWidth: true,
    validateArgs: {
      required: 'Это поле обязательно',
    },
  },
];

const GetcourseDialog: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector(({ Users }: RootState) => Users);
  const { isOpen } = useSelector(({ Dialogs: { getcourse } }: RootState) => getcourse);
  const [loading, setLoading] = React.useState(false);
  const {
    register, handleSubmit, setError, errors,
  } = useForm();

  const handleClose = useCallback(
    () => {
      dispatch(getcourseDialogToggleHide());
    },
    [dispatch],
  );
  fields[0].defaultValue = user?.first_name ?? '';
  fields[1].defaultValue = user?.last_name ?? '';
  fields[2].defaultValue = user?.phone ?? '';
  if (!/[0-9]@sokolov.partners/i.test(user?.email ?? '')) {
    fields[3].defaultValue = user?.email ?? '';
  }

  const onSubmit = useCallback(
    (data) => {
      const payload: {[key: string]: unknown} = {};
      Object.entries(data).forEach(([key, value]) => {
        payload[snakeCase(key)] = value;
      });
      setLoading(true);
      dispatch(getcourseFormSubmit({
        payload: JSON.stringify(payload),
        cbError: setError,
        cbCompleted: () => setLoading(false),
      }));
    },
    [setError, dispatch],
  );

  return (
    <div>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">
            {t('getcourseTitle')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('getcourseSync1Text')}
              &nbsp;
              <Link href="https://sales.sokolov.training/cms/system/login" target="_blank">
                GetCourse
              </Link>
              &nbsp;
              (
              {t('getcourseSync2Text')}
              ).
            </DialogContentText>
            {fields.map(({
              key, label, id, fullWidth, validateArgs, ...other
            }) => {
              const error = errors[key];
              return (
                <FormControl key={key} margin="normal" fullWidth={fullWidth}>
                  <InputLabel htmlFor={id}>{t(label)}</InputLabel>
                  <Input
                    id={id}
                    {...other}
                    error={!!error}
                    inputRef={register(validateArgs)}
                  />
                  {error && (
                    <FormHelperText error>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              {t('cancel')}
            </Button>
            <Button color="primary" type="submit" disabled={loading}>
              { loading && (
                <CircularProgress size="1rem" />
              )}
              {t('get_access')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default GetcourseDialog;
