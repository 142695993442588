import React, {
  FC,
  useCallback,
  memo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Link,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { snakeCase } from 'lodash';
import { withdrawFormSubmit, withdrawDialogToggleHide } from '../../store/actions/Dialogs';
import { RootState } from '../../store';

const fields = [
  {
    key: 'address',
    name: 'address',
    id: 'address',
    type: 'text',
    label: 'addressBlockchain',
    autoFocus: true,
    fullWidth: true,
    validateArgs: {
      required: 'Это поле обязательно',
    },
  },
  {
    key: 'amount',
    name: 'amount',
    id: 'amount',
    type: 'text',
    label: 'Amount',
    fullWidth: true,
    validateArgs: {
      required: 'Это поле обязательно',
    },
  },
];

const WithdrawDialog: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isOpen } = useSelector(({ Dialogs: { withdraw } }: RootState) => withdraw);
  const {
    register, handleSubmit, setError, errors,
  } = useForm();

  const handleClose = useCallback(
    () => {
      dispatch(withdrawDialogToggleHide());
    },
    [dispatch],
  );
  const [loading, setsetLoading] = useState(false);

  const onSubmit = useCallback(
    (data) => {
      const payload: {[key: string]: unknown} = {};
      Object.entries(data).forEach(([key, value]) => {
        payload[snakeCase(key)] = value;
      });
      setsetLoading(true);
      dispatch(withdrawFormSubmit({
        payload: JSON.stringify(payload),
        cbError: setError,
        cbCompleted: () => setsetLoading(false),
      }));
    },
    [setError, dispatch, setsetLoading],
  );
  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">
          {t('send_withdraw_request_title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('send_withdraw_request_fill_form')}
          </DialogContentText>
          {fields.map(({
            key, label, id, fullWidth, validateArgs, ...other
          }) => {
            const error = errors[key];
            return (
              <FormControl key={key} margin="normal" fullWidth={fullWidth}>
                <InputLabel htmlFor={id}>{t(label)}</InputLabel>
                <Input
                  id={id}
                  {...other}
                  error={!!error}
                  inputRef={register(validateArgs)}
                />
                {error && (
                  <FormHelperText error>
                    {error.message}
                  </FormHelperText>
                )}
              </FormControl>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Link href="https://login.blockchain.com/#/signup" target="_blank">
            {t('register_wallet')}
          </Link>
          <Button color="primary" onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button color="primary" type="submit" disabled={loading}>
            { loading && (
              <CircularProgress size="1rem" />
            )}
            {t('send_withdraw_request')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(WithdrawDialog);
