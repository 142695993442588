import React, { FC, Suspense, useCallback } from 'react';
import {
  CssBaseline, makeStyles, Theme, withStyles,
} from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import { outsideRoutes } from '../../routes';
import Page404 from '../../views/Page404';
import { RootState } from '../../store';
import styles from './styles';


const useStyles = makeStyles(styles, { name: 'AuthLayout' });

const GlobalStyle = withStyles((theme: Theme) => ({
  '@global': {
    // eslint-disable-next-line
    '& body': { background: theme.body.background },
  },
}))(() => null);

const AuthLayout: FC = (props) => {
  const classes = useStyles(props);
  const { token } = useSelector(({ Auth }: RootState) => Auth, shallowEqual);
  const routerRender = useCallback(
    (Component) => (): JSX.Element => (
      !token
        ? (
          <Suspense fallback={<div />}>
            <Component />
          </Suspense>
        )
        : <Redirect to="/" />
    ),
    [token],
  );
  return (
    <div className={classes.root}>
      <CssBaseline />
      <GlobalStyle />
      <Switch>
        {outsideRoutes.map(({ path, component, exact }) => (
          <Route
            exact={exact}
            path={path}
            key={path}
            render={routerRender(component)}
          />
        ))}
        <Route
          component={Page404}
        />
      </Switch>
    </div>
  );
};

export default AuthLayout;
