import { createAction } from '@reduxjs/toolkit';
import {
  DIALOGS_CONTACT_FORM_SUBMIT,
  DIALOGS_CONTACT_FORM_SUBMIT_FAILED,
  DIALOGS_CONTACT_FORM_SUBMIT_SUCCESS,
  DIALOGS_CONTACT_TOGGLE,
  DIALOGS_CONTACT_TOGGLE_HIDE,
  DIALOGS_CONTACT_TOGGLE_SHOW,
} from '../../actionTypes';


export const contactFormSubmit = createAction<
  unknown,
  typeof DIALOGS_CONTACT_FORM_SUBMIT
>(DIALOGS_CONTACT_FORM_SUBMIT);

export const contactFormSubmitSuccess = createAction<
  void,
  typeof DIALOGS_CONTACT_FORM_SUBMIT_SUCCESS
>(DIALOGS_CONTACT_FORM_SUBMIT_SUCCESS);

export type contactFormSubmitFailedPayload = { message: string }
export const contactFormSubmitFailed = createAction<
  contactFormSubmitFailedPayload,
  typeof DIALOGS_CONTACT_FORM_SUBMIT_FAILED
>(DIALOGS_CONTACT_FORM_SUBMIT_FAILED);

export const contactDialogToggle = createAction<
  void,
  typeof DIALOGS_CONTACT_TOGGLE
>(DIALOGS_CONTACT_TOGGLE);

export const contactDialogToggleShow = createAction<
  void,
  typeof DIALOGS_CONTACT_TOGGLE_SHOW
>(DIALOGS_CONTACT_TOGGLE_SHOW);

export const contactDialogToggleHide = createAction<
  void,
  typeof DIALOGS_CONTACT_TOGGLE_HIDE
>(DIALOGS_CONTACT_TOGGLE_HIDE);
