import React from 'react';

const DefaultHeader = React.lazy(() => import('../modules/Header/Subcomponents/DefaultViewHeader'));

const SignIn = React.lazy(() => import('../views/SignIn'));
const SignUp = React.lazy(() => import('../views/SignUp'));
const PasswordRecovery = React.lazy(() => import('../views/PasswordRecovery'));
const ResetPassword = React.lazy(() => import('../views/ResetPassword'));
// const ResetPassword = React.lazy(() => import('../views/ResetPassword'));

const Home = React.lazy(() => import('../views/Home'));
const HomeHeader = React.lazy(() => import('../views/Home/Subcomponents/HomeHeader'));

const ProfileEdit = React.lazy(() => import('../views/ProfileEdit'));
const Team = React.lazy(() => import('../views/Team'));
const Marketplace = React.lazy(() => import('../views/Marketplace'));
const Contacts = React.lazy(() => import('../views/Contacts'));
const ContactsActivity = React.lazy(() => import('../views/Contacts/Subcomponents/ContactsActivity'));
const Finances = React.lazy(() => import('../views/Finances'));
const ProductDetail = React.lazy(() => import('../modules/ProductDetail'));
const NewsDetail = React.lazy(() => import('../modules/NewsDetail'));
const Pages = React.lazy(() => import('../views/Pages'));
const CalendarEvents = React.lazy(() => import('../views/CalendarEvents'));
const UserFlows = React.lazy(() => import('../views/UserFlows'));
const UserFlowsLandingEdit = React.lazy(() => import('../views/UserFlows/Subcomponents/LandingEdit'));
const ModulesFlowChart = React.lazy(() => import('../views/ModulesFlowChart'));
const ViewUserCourse = React.lazy(() => import('../views/UserCourses/Subcomponents/ViewUserCourse'));
const ViewUserModule = React.lazy(() => import('../views/UserCourses/Subcomponents/ViewUserModule'));
const UserCourses = React.lazy(() => import('../views/UserCourses'));
const ViewCourseModule = React.lazy(() => import('../views/ViewCourseModule'));
const Statistics = React.lazy(() => import('../views/Statistics'));
const UserGroupsList = React.lazy(() => import('../views/UserGroups/UserGroupsList'));
const UserTasksList = React.lazy(() => import('../views/UserTasksList/UserTasksList'));
const AssignedTasks = React.lazy(() => import('../views/AssignedTasks/AssignedTasks'));
const UserGroupView = React.lazy(() => import('../views/UserGroups/UserGroupView'));
const UserDetailsView = React.lazy(() => import('../views/UserGroups/UserDetailsView'));
const UserAchievements = React.lazy(() => import('../views/UserGroups/UserAchievements'));
const MyCourses = React.lazy(() => import('../views/MyCourses/MyCourses'));
const SaveCourse = React.lazy(() => import('../views/MyCourses/CourseForm/SaveCourse'));
const MyProducts = React.lazy(() => import('../views/MyProducts/MyProducts'));
const SaveProduct = React.lazy(() => import('../views/MyProducts/ProductForm/SaveProduct'));

export const outsideRoutes = [
  {
    path: '/sign-in',
    name: 'Sign In',
    component: SignIn,
    exact: true,
  },
  {
    path: '/sign-up',
    name: 'Sign Up',
    component: SignUp,
    exact: true,
  },
  {
    path: '/password-recovery',
    name: 'Password Recovery',
    component: PasswordRecovery,
    exact: true,
  },
  {
    path: '/password-reset/:key',
    name: 'Password Reset',
    component: ResetPassword,
    exact: true,
  },
  // {
  //   path: '/reset-password',
  //   name: 'Reset Password',
  //   component: ResetPassword,
  //   exact: true,
  // },
];

export const insideRoutes = [
  {
    path: '/',
    title: 'dashboard',
    component: Home,
    headerComponent: HomeHeader,
    exact: true,
  },
  {
    path: '/my-team',
    title: 'my_team',
    component: Team,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/statistic',
    title: 'statistic_analytics',
    component: Home,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/profile/edit',
    title: 'profile_edit',
    component: ProfileEdit,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/products/:category',
    title: 'products_cat',
    component: Marketplace,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/product/:pk',
    title: 'product_info',
    component: ProductDetail,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/news/:pk',
    title: 'news_view',
    component: NewsDetail,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/contacts',
    title: 'contacts',
    component: Contacts,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/contacts/activity/:pk',
    title: 'contacts_activity',
    component: ContactsActivity,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/finances',
    title: 'finances',
    component: Finances,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/pages/:slug',
    title: '',
    component: Pages,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/user_flows',
    title: 'user_flows',
    component: UserFlows,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/user_flows/edit_landing/:landing_pk',
    title: 'user_flows_edit_landing',
    component: UserFlowsLandingEdit,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/statistics',
    title: 'statistics',
    component: Statistics,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/course_flowchart/:pk',
    title: 'course_flowchart',
    component: ModulesFlowChart,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/course/module/:pk',
    title: 'course_module',
    component: ViewCourseModule,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/view_course/:pk',
    title: 'view_course',
    component: ViewUserCourse,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/view_module/:coursePk/:pk',
    title: 'view_module',
    component: ViewUserModule,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/purchased_courses',
    title: 'purchased_courses',
    component: UserCourses,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/assigned_tasks',
    title: 'assigned_tasks',
    component: AssignedTasks,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/user_tasks',
    title: 'user_tasks',
    component: UserTasksList,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/user_groups',
    title: 'user_groups',
    component: UserGroupsList,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/user_groups/view/:pk',
    title: 'user_groups_view',
    component: UserGroupView,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/user_groups/user/details/:pk',
    title: 'user_groups_user_details',
    component: UserDetailsView,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/user_achievements',
    title: 'achievements_list',
    component: UserAchievements,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/calendar_events',
    title: 'Calendar Events',
    component: CalendarEvents,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/my_courses/list',
    title: 'My Courses',
    component: MyCourses,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/my_courses/create',
    title: 'Create course',
    component: SaveCourse,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/my_courses/update/:pk',
    title: 'Update course',
    component: SaveCourse,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/my_products/list',
    title: 'My Products',
    component: MyProducts,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/my_products/create',
    title: 'Create product',
    component: SaveProduct,
    headerComponent: DefaultHeader,
    exact: true,
  },
  {
    path: '/my_products/update/:pk',
    title: 'Update product',
    component: SaveProduct,
    headerComponent: DefaultHeader,
    exact: true,
  },
  // {
  //   path: '/products_packages',
  //   title: 'products_packages',
  //   component: ProductsPackagesList,
  //   headerComponent: DefaultHeader,
  //   exact: true,
  // },
  // {
  //   path: '/products_packages/purchased',
  //   title: 'products_packages_purchased',
  //   component: PurchasedPackages,
  //   headerComponent: DefaultHeader,
  //   exact: true,
  // },
];

export const outsideRoutesPaths = outsideRoutes.map(({ path }) => path);
export const insideRoutesPaths = insideRoutes.map(({ path }) => path);
