import { createStyles, Theme } from '@material-ui/core';


export default (theme: Theme) => createStyles({
  packLabel: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  packLabelText: {
    display: 'contents',
  },
  packLabelOldPrice: {
    verticalAlign: 'super',
    textDecoration: 'line-through',
    display: 'inline-block',
  },
  promotionalTitle: {
    textAlign: 'center',
    marginTop: '-5px',
    marginBottom: '10px',
  },
  packs: {
    display: 'flex',
    '& span': {
      verticalAlign: 'super',
    },
  },
  loading: {
    textAlign: 'center',
  },
  promoCode: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '400',
  },
  promocodeInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  promoCodeIconButton: {
    padding: 10,
  },
});
