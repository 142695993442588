/* eslint-disable max-len */
import { createAction } from '@reduxjs/toolkit';
import { SNACK_TOGGLE, SNACK_TOGGLE_HIDE, SNACK_TOGGLE_SHOW } from '../actionTypes';
import { ThrowSnackState } from '../interfaces/ThrowSnack';

export type snackTogglePayload = Partial<ThrowSnackState>
export const snackToggle = createAction<
  snackTogglePayload,
  typeof SNACK_TOGGLE
>(SNACK_TOGGLE);
export const snackToggleShow = createAction<
  snackTogglePayload,
  typeof SNACK_TOGGLE_SHOW
>(SNACK_TOGGLE_SHOW);
export const snackToggleHide = createAction<
  void,
  typeof SNACK_TOGGLE_HIDE
>(SNACK_TOGGLE_HIDE);
