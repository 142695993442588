import { all } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/core';

import withdrawDialog from './withdrawDialog';
import getcourseDialog from './getcourseDialog';
import subscriptionDialog from './subscriptionDialog';
import activationDialog from './activationDialog';
import contactDialog from './contactDialog';

export default function* dialogsSaga(): SagaIterator {
  // @ts-ignore
  yield all([
    withdrawDialog(),
    getcourseDialog(),
    subscriptionDialog(),
    activationDialog(),
    contactDialog(),
  ]);
}
