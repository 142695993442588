/* eslint-disable no-console */
import ls from './ls';
import {
  LogFetch, FetchInit, FetchResult, FetchArgs,
} from './types';

const logFetch = ({
  type = 'Fetch log', method = 'GET', payloadUrl, payload, success, status, response,
}: LogFetch): void => {
  console.group(type);
  if (!process.env.API_URL) {
    console.warn('Set environment API_URL key');
  }
  console.debug(method, 'request', payloadUrl, payload);
  console.debug(method, 'response success', success);
  console.debug(method, 'response status', status);
  console.debug(method, 'response body', response);
  console.groupEnd();
};
/* eslint-enable */

// eslint-disable-next-line max-len
const tryRequest = async <ResponseType = unknown>(fetchUrl: string, payload: FetchInit): Promise<FetchResult<ResponseType>> => {
  let response;
  let status;
  let success;

  try {
    const res = await fetch(fetchUrl, payload)
      .catch(() => {
        status = 503;
        throw new Error('Service Unavailable');
      });

    status = res.status;
    success = res.ok;
    response = await res
      .json()
      .catch(() => {
        response = undefined;
      });
  } catch (e) {
    success = false;
    response = e.message;
  }

  return { status, success, response };
};


export default async function fetchApi<ResponseType = unknown>(
  { method = 'GET', payloadUrl, payload = '' }: FetchArgs,
): Promise<FetchResult<ResponseType>> {
  let url = payloadUrl;
  if (!payloadUrl.startsWith('/')) {
    url = `/${url}`;
  }
  // if (!payloadUrl.endsWith('/')) {
  //   url = `${url}/`;
  // }

  const fetchUrl = `${process.env.API_URL}${url}`;
  const { token: lsToken = '' } = ls.get<{token?: string}>('persist:auth', {});
  const token = JSON.parse(lsToken);

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (token) {
    headers.append('Authorization', `JWT ${token}`);
  }

  const init: FetchInit = {
    method,
    mode: 'cors',
    headers,
  };

  if (method !== 'GET' && payload) {
    init.body = payload;
  }

  const { response, status, success } = await tryRequest<ResponseType>(fetchUrl, init);

  if (process.env.NODE_ENV !== 'production') {
    logFetch({
      type: 'fetchApi', method, payloadUrl, payload, success, status, response,
    });
  }
  return { success, response, status };
}

export async function fetchApiFiles<ResponseType>(
  { method = 'GET', payloadUrl, payload = '' }: FetchArgs,
): Promise<FetchResult<ResponseType>> {
  let url = payloadUrl;
  if (!payloadUrl.startsWith('/')) {
    url = `/${url}`;
  }
  if (!payloadUrl.endsWith('/')) {
    url = `${url}/`;
  }

  const fetchUrl = `${process.env.API_URL}${url}`;
  const { token: lsToken = '' } = ls.get<{token?: string}>('persist:auth', {});
  const token = JSON.parse(lsToken);

  const headers = new Headers();
  if (token) {
    headers.append('Authorization', `JWT ${token}`);
  }

  const init: FetchInit = {
    method,
    mode: 'cors',
    headers,
    body: payload,
  };

  const { response, status, success } = await tryRequest<ResponseType>(fetchUrl, init);

  if (process.env.NODE_ENV !== 'production') {
    logFetch({
      type: 'fetchApiFiles', method, payloadUrl, payload, success, status, response,
    });
  }
  return { success, response, status };
}
