import { all } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/core';

import authSaga from './Auth';
import usersSaga from './Users';
import productsSaga from './Products';
import productCategoriesSaga from './ProductCategories';
import dialogsSaga from './Dialogs';
import paymentSaga from './PaymentDialog';


export default function* rootSaga(): SagaIterator {
  // @ts-ignore
  yield all([
    authSaga(),
    usersSaga(),
    dialogsSaga(),
    productsSaga(),
    productCategoriesSaga(),
    paymentSaga(),
  ]);
}
