import { call, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/core';

import fetchApi from '../../utils/fetchApi';
import {
  productCategoriesGetItems,
  productCategoriesGetItemsFailed,
  productCategoriesGetItemsSuccess,
} from '../actions/ProductCategories';


function* getItems(): SagaIterator {
  try {
    const { response, success } = yield call(
      fetchApi,
      {
        payloadUrl: 'products/categories/',
      },
    );
    if (success) {
      yield put(productCategoriesGetItemsSuccess(response));
      return;
    }
  } catch ({ message }) {
    yield put(productCategoriesGetItemsFailed({ message }));
  }
}


export default function* (): SagaIterator {
  yield takeEvery(productCategoriesGetItems.type, getItems);
}
