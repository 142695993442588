import { createStyles, Theme } from '@material-ui/core';


export default (theme: Theme) => createStyles({
  appBar: {
    background: theme.header.background,
    color: theme.header.color,
    boxShadow: theme.shadows[1],
  },
  iconButton: {
    '& svg': {
      width: 22,
      height: 22,
    },
  },
});
