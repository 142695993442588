import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';

import { signInSuccess, signOut, tokenRefreshSuccess } from '../actions/Auth';
import {
  profileUpdateEmailSuccess,
  profileUpdateSuccess,
  userWalletUpdate,
  linkCopiedSuccess,
} from '../actions/Users';
import { UsersState } from '../interfaces/Users';


const INITIAL_STATE: UsersState = {
  user: {
    id: 0,
    email: '',
    refferal_link: '',
    referral_free: '',
    is_in_partners_group: false,
    is_telegram_linked: false,
    is_superuser: false,
    language: '',
    referral_pay: '',
    avatar_165: '',
    first_name: '',
    last_name: '',
    date_joined: '',
    promocode: '',
    phone: '',
    getcourse_user: {},
    users_referral_structure: {
      depth: 0,
      parent: {
        user: null,
      },
    },
    wallet: null,
    subscribe: {
      end_date: '',
    },
  },
};

type Builder = ActionReducerMapBuilder<UsersState>
const actionsMap = (builder: Builder): Builder => builder
  .addCase(
    signInSuccess,
    (state, { payload: { user } }) => ({
      ...state,
      user,
    }),
  )
  .addCase(
    tokenRefreshSuccess,
    (state, { payload: { user } }) => ({
      ...state,
      user,
    }),
  )
  .addCase(
    profileUpdateSuccess,
    (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        ...payload,
      },
    }),
  )
  .addCase(
    linkCopiedSuccess,
    () => INITIAL_STATE,
  )
  .addCase(
    profileUpdateEmailSuccess,
    () => INITIAL_STATE,
  )
  .addCase(
    userWalletUpdate,
    (state, { payload }) => ({
      user: {
        ...state.user,
        wallet: payload,
      },
    }),
  )
  .addCase(
    signOut,
    () => INITIAL_STATE,
  );

export default createReducer(
  INITIAL_STATE,
  actionsMap,
);
