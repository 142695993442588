import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';

import { productCategoriesGetItemsSuccess } from '../actions/ProductCategories';
import { ProductCategoryState } from '../interfaces/ProductCategories';


const INITIAL_STATE: ProductCategoryState = {
  categories: [],
};

type Builder = ActionReducerMapBuilder<ProductCategoryState>
const actionsMap = (builder: Builder): Builder => builder
  .addCase(
    productCategoriesGetItemsSuccess,
    (state, { payload }) => ({
      ...state,
      categories: payload,
    }),
  );

export default createReducer(
  INITIAL_STATE,
  actionsMap,
);
