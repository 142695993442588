import React, { FC, Suspense, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid, Hidden, Toolbar, AppBar, IconButton, makeStyles,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { insideRoutes } from '../../routes';
import UserMenu from './Subcomponents/UserMenu';
import Props from './types';
import { RootState } from '../../store';
import styles from './styles';
import fetchApi from '../../utils/fetchApi';

const useStyles = makeStyles(styles, { name: 'Header' });

const Header: FC<Props> = (props) => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const { user } = useSelector(({ Users }: RootState) => Users);
  const { first_name: firstName = '' } = user || {};
  const { onDrawerToggle } = props;
  const {
    avatarUrl = '',
  } = props;

  const handleChangeLanguage = useCallback(
    async (language) => {
      const { response, success } = await fetchApi({
        method: 'POST',
        payloadUrl: '/accounts/set_language/',
        payload: JSON.stringify({
          language,
        }),
      });
      console.log('handleChangeLanguage');
      console.log(success);
      if (success) {
        await i18n.changeLanguage(language);
        await window.location.reload();
      }
    },
    [],
  );

  return (
    <AppBar position="sticky" elevation={0} className={classes.appBar}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                className={classes.iconButton}
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Switch>
              {insideRoutes.map(({
                headerComponent: Component, path, exact, title,
              }) => (
                <Route
                  key={path}
                  path={path}
                  exact={exact}
                >
                  <Suspense fallback={<div />}>
                    <Component firstName={firstName} title={t(title)} />
                  </Suspense>
                </Route>
              ))}
            </Switch>
          </Hidden>
          <Grid item xs />
          <Grid item>
            <div className="select">
              <select
                value={i18n.language}
                onChange={(e) => (handleChangeLanguage(e.target.value))}
              >
                <option value="en">EN</option>
                <option value="ru">RU</option>
                <option value="uk">UA</option>
              </select>
            </div>
          </Grid>
          <Grid item>
            <UserMenu avatarUrl={avatarUrl} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
