import React, { FC } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import styles from '../styles';

import { ProductPriceProps } from '../types';


const useStyles = makeStyles(styles, { name: 'ProductPrice' });

const ProductPrice: FC<ProductPriceProps> = ({
  oldPrice,
  newPrice,
}) => {
  const classes = useStyles();

  return (
    <Typography className={classes.productPrice}>
      {Number(oldPrice) !== Number(newPrice) && (
        <>
          <Typography
            className={classes.productOldPrice}
          >
            {`${oldPrice}`}
            &nbsp;
          </Typography>
        </>
      )}
      <Typography className={classes.productPrice}>{`${newPrice} SLC`}</Typography>
    </Typography>
  );
};

export default ProductPrice;
