import React, { FC, ReactElement } from 'react';

import CommentIcon from '@material-ui/icons/Comment';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ReportIcon from '@material-ui/icons/Report';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import GradeIcon from '@material-ui/icons/Grade';
import GroupIcon from '@material-ui/icons/Group';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import AssistantIcon from '@material-ui/icons/Assistant';
import AttachmentIcon from '@material-ui/icons/Attachment';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import ArchiveIcon from '@material-ui/icons/Archive';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { Monitor, Users } from 'react-feather';
import ContactsIcon from '@material-ui/icons/Contacts';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AppsIcon from '@material-ui/icons/Apps';

import { MenuIconProps } from '../types';


const icons: {[key: string]: ReactElement} = {
  // Status icons
  BookmarkIcon: <BookmarkIcon />,
  CommentIcon: <CommentIcon />,
  PersonAddIcon: <PersonAddIcon />,
  ReportIcon: <ReportIcon />,
  PhotoLibraryIcon: <PhotoLibraryIcon />,
  ThumbsUpDownIcon: <ThumbsUpDownIcon />,
  AssignmentTurnedInIcon: <AssignmentTurnedInIcon />,
  GradeIcon: <GradeIcon />,
  GroupIcon: <GroupIcon />,
  SubscriptionsIcon: <SubscriptionsIcon />,
  VerifiedUserIcon: <VerifiedUserIcon />,
  WatchLaterIcon: <WatchLaterIcon />,
  // Enviropment icons
  HT: <AssistantIcon />,
  WM: <AttachmentIcon />,
  CD: <AcUnitIcon />,
  AR: <ArchiveIcon />,
  Monitor: <Monitor />,
  ContactsIcon: <ContactsIcon />,
  LocalAtmIcon: <LocalAtmIcon />,
  Users: <Users />,
  AppsIcon: <AppsIcon />,
};

const MenuIcon: FC<MenuIconProps> = (props) => {
  const {
    iconName,
  } = props;

  return (
    <>
      {icons[iconName]}
    </>
  );
};


export default MenuIcon;
