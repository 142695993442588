import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import withdrawDialog from './withdrawDialog';
import { DialogsState } from '../../interfaces/Dialogs';
import getcourseDialog from './getcourseDialog';
import subscriptionDialog from './subscriptionDialog';
import activationDialog from './activationDialog';
import contactDialog from './contactDialog';


const INITIAL_STATE: DialogsState = {
  withdraw: withdrawDialog.INITIAL_STATE,
  getcourse: getcourseDialog.INITIAL_STATE,
  subscription: subscriptionDialog.INITIAL_STATE,
  activation: activationDialog.INITIAL_STATE,
  contact: contactDialog.INITIAL_STATE,
};

type Builder = ActionReducerMapBuilder<DialogsState>
const actionsMap = (builder: Builder): Builder => {
  const builderResult = builder;

  withdrawDialog.actionsMap.forEach(({ action, reducer }) => builderResult.addCase(
    action,
    (state: DialogsState, actionCase) => ({
      ...state,
      // @ts-ignore
      withdraw: reducer(state.withdraw, actionCase),
    }),
  ));

  getcourseDialog.actionsMap.forEach(({ action, reducer }) => builderResult.addCase(
    action,
    (state: DialogsState, actionCase) => ({
      ...state,
      // @ts-ignore
      getcourse: reducer(state.getcourse, actionCase),
    }),
  ));

  subscriptionDialog.actionsMap.forEach(({ action, reducer }) => builderResult.addCase(
    action,
    (state: DialogsState, actionCase) => ({
      ...state,
      // @ts-ignore
      subscription: reducer(state.subscription, actionCase),
    }),
  ));

  activationDialog.actionsMap.forEach(({ action, reducer }) => builderResult.addCase(
    action,
    (state: DialogsState, actionCase) => ({
      ...state,
      // @ts-ignore
      activation: reducer(state.activation, actionCase),
    }),
  ));

  contactDialog.actionsMap.forEach(({ action, reducer }) => builderResult.addCase(
    action,
    (state: DialogsState, actionCase) => ({
      ...state,
      // @ts-ignore
      contact: reducer(state.contact, actionCase),
    }),
  ));

  return builderResult;
};

export default createReducer(
  INITIAL_STATE,
  actionsMap,
);
