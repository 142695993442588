import React, { FC, useCallback, useState } from 'react';
import {
  ListItemText,
  makeStyles,
  Collapse,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { NavLink as RouterNavLink } from 'react-router-dom';
import { useMount } from 'react-use';

import styles, { activeClassName } from '../styles';
import fetchApi from '../../../utils/fetchApi';
import menuItems from '../menuItems';
import { MenuObject } from '../types';
import MenuIcon from './MenuIcon';
import SidebarCategory from './SidebarCategory';
import { Props } from './types';

const useStyles = makeStyles(styles, { name: 'Sidebar' });
const initialMenu: MenuObject[] = [];

const MenuItems: FC<Props> = (props) => {
  const classes = useStyles();
  const [openArray, setOpen] = React.useState([]);
  const [menu, setMenu] = useState(initialMenu);
  const {
    onClick,
  } = props;

  const loadData = useCallback(
    async (payloadUrl: string): Promise<void> => {
      const { response, success } = await fetchApi<MenuObject[]>({
        payloadUrl,
      });
      if (!success) {
        setMenu(initialMenu);
      }

      if (success && response) {
        // const { results } = response;
        setMenu(response);
      }
    },
    [setMenu, menu],
  );

  useMount(() => {
    setTimeout(() => { loadData('/menu/get-menu/'); }, 1000);
  });

  const isOpen = useCallback(
    // eslint-disable-next-line
    (key: number) => {
      // @ts-ignore
      return openArray.includes(key);
    },
    [openArray],
  );

  const handleToggle = useCallback(
    (key: number) => (): void => {
      const newArray = [];
      // @ts-ignore
      if (openArray.includes(key)) {
        openArray.forEach((element: number) => {
          if (element !== key) {
            newArray.push(element);
          }
        });
      } else {
        openArray.forEach((element: number) => {
          newArray.push(element);
        });
        newArray.push(key);
      }
      // @ts-ignore
      setOpen(newArray);
    },
    [openArray, setOpen],
  );

  return (
    <>
      {menu.map((
        {
          id, title, icon, url, children, is_parent: isParent, menu_type: menuType,
        },
      ) => (
        <>
          {menuType === 'marketplace' ? (
            <>
              {menuItems.map((item) => (
                <SidebarCategory
                  {...item}
                  onClick={onClick}
                  activeClassName={activeClassName}
                />
              ))}
            </>
          ) : (
            <>
              {isParent ? (
                <>
                  <RouterNavLink
                    key={id}
                    className={`MuiListItem-root MuiListItem-gutters ${classes.category}`}
                    to={url}
                    exact
                    onClick={handleToggle(id)}
                  >
                    <MenuIcon iconName={icon} />
                    <ListItemText className={classes.categoryText}>{title}</ListItemText>
                    {isOpen(id) ? (
                      <ExpandLess className={classes.categoryIcon} />
                    ) : (
                      <ExpandMore className={classes.categoryIcon} />
                    )}
                  </RouterNavLink>
                  <Collapse
                    in={isOpen(id)}
                  >
                    {children.map((
                      {
                        id: sId, title: sTitle, icon: sIcon, url: sUrl,
                      },
                    ) => (
                      <RouterNavLink
                        key={sId}
                        className={`MuiListItem-root MuiListItem-gutters ${classes.category}`}
                        to={sUrl}
                        onClick={onClick}
                        exact
                      >
                        <MenuIcon iconName={sIcon} />
                        <ListItemText className={classes.categoryText}>{sTitle}</ListItemText>
                      </RouterNavLink>
                    ))}
                  </Collapse>
                </>
              ) : (
                <RouterNavLink
                  className={`MuiListItem-root MuiListItem-gutters ${classes.category}`}
                  to={url}
                  onClick={onClick}
                  exact
                >
                  <MenuIcon iconName={icon} />
                  <ListItemText className={classes.categoryText}>{title}</ListItemText>
                  {/* {!!icon && <Chip label={icon} className={classes.linkBadge} />} */}
                </RouterNavLink>
              )}
            </>
          )}
        </>
      ))}
    </>
  );
};

MenuItems.defaultProps = {};

export default MenuItems;
