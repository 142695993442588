import { createAction } from '@reduxjs/toolkit';
import {
  DIALOGS_PAYMENT_FORM_SUBMIT,
  DIALOGS_PAYMENT_FORM_SUBMIT_FAILED,
  DIALOGS_PAYMENT_FORM_SUBMIT_SUCCESS,
  DIALOGS_PAYMENT_TOGGLE,
  DIALOGS_PAYMENT_TOGGLE_HIDE,
  DIALOGS_PAYMENT_TOGGLE_SHOW,
} from '../../actionTypes';


export const paymentFormSubmit = createAction<
  unknown,
  typeof DIALOGS_PAYMENT_FORM_SUBMIT
  >(DIALOGS_PAYMENT_FORM_SUBMIT);

export const paymentFormSubmitSuccess = createAction<
  void,
  typeof DIALOGS_PAYMENT_FORM_SUBMIT_SUCCESS
  >(DIALOGS_PAYMENT_FORM_SUBMIT_SUCCESS);

export type paymentFormSubmitFailed = {message: string}
export const paymentFormSubmitFailed = createAction<
  paymentFormSubmitFailed,
  typeof DIALOGS_PAYMENT_FORM_SUBMIT_FAILED
  >(DIALOGS_PAYMENT_FORM_SUBMIT_FAILED);

export const paymentDialogToggle = createAction<
  void,
  typeof DIALOGS_PAYMENT_TOGGLE
  >(DIALOGS_PAYMENT_TOGGLE);

export type paymentDialogToggleShowPayload = {
  productPk: number;
  amount: number;
}
export const paymentDialogToggleShow = createAction<
  paymentDialogToggleShowPayload,
  typeof DIALOGS_PAYMENT_TOGGLE_SHOW
  >(DIALOGS_PAYMENT_TOGGLE_SHOW);

export const paymentDialogToggleHide = createAction<
  void,
  typeof DIALOGS_PAYMENT_TOGGLE_HIDE
  >(DIALOGS_PAYMENT_TOGGLE_HIDE);
