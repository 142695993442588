import {
  call, put, select, takeEvery,
} from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';


import fetchApi, { fetchApiFiles } from '../../utils/fetchApi';
import {
  profileUpdate as profileUpdateAction,
  profileUpdateEmailSuccess,
  profileUpdateFailed,
  profileUpdateSuccess,
  profileUpdatePayload,
} from '../actions/Users';
import { parseForHookForm } from '../../utils/parseResponseErrors';
import { ResponseErrors } from '../../utils/types';

function* profileUpdate(
  { payload: { payload, cbError } }: PayloadAction<profileUpdatePayload>,
): SagaIterator {
  try {
    const { Users: { user: { id } } } = yield select();
    const { response, status } = yield call(
      typeof payload === 'string' ? fetchApi : fetchApiFiles,
      {
        method: 'PATCH',
        payloadUrl: `/accounts/users/${id}/`,
        payload,
      },
    );
    if (status === 200) {
      if (!response) {
        yield put(profileUpdateEmailSuccess());
      } else {
        yield put(profileUpdateSuccess(response));
      }
    } else {
      parseForHookForm<ResponseErrors>(response, cbError);
      throw new Error('Форма не валидна');
    }
  } catch (e) {
    yield put(profileUpdateFailed({ message: e.message }));
  }
}

export default function* (): SagaIterator {
  yield takeEvery(profileUpdateAction.type, profileUpdate);
}
