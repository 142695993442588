// Auth
export const AUTH_TOKEN_VERIFY = 'Auth/tokenVerify';
export const AUTH_TOKEN_REFRESH = 'Auth/tokenRefresh';
export const AUTH_TOKEN_REFRESH_SUCCESS = 'Auth/tokenRefreshSuccess';
export const AUTH_TOKEN_REFRESH_FAILED = 'Auth/tokenRefreshFailed';
export const AUTH_SIGN_IN = 'Auth/signIn';
export const AUTH_SIGN_UP = 'Auth/signUp';
export const AUTH_SIGN_IN_BY_BOT = 'Auth/signInByBot';
export const AUTH_SIGN_IN_SUCCESS = 'Auth/signInSuccess';
export const AUTH_SIGN_IN_FAILED = 'Auth/signInFailed';
export const AUTH_SIGN_OUT = 'Auth/signOut';

// Users
export const USERS_PROFILE_UPDATE = 'Users/profileUpdate';
export const USERS_PROFILE_UPDATE_SUCCESS = 'Users/profileUpdateSuccess';
export const USERS_PROFILE_UPDATE_FAILED = 'Users/profileUpdateFailed';
export const USERS_WALLET_UPDATE = 'Users/updateWallet';
export const USERS_PROFILE_UPDATE_EMAIL_SUCCESS = 'Users/profileUpdateEmailSuccess';
export const USERS_LINK_COPIED_SUCCESS = 'Users/linkCopiedSuccess';

// ThrowSnack
export const SNACK_TOGGLE = 'ThrowSnack/toggle';
export const SNACK_TOGGLE_SHOW = 'ThrowSnack/toggleShow';
export const SNACK_TOGGLE_HIDE = 'ThrowSnack/toggleHide';

// WithdrawDialog
export const DIALOGS_WITHDRAW_FORM_SUBMIT = 'Dialogs/withdrawSubmit';
export const DIALOGS_WITHDRAW_FORM_SUBMIT_SUCCESS = 'Dialogs/withdrawSubmitSuccess';
export const DIALOGS_WITHDRAW_FORM_SUBMIT_FAILED = 'Dialogs/withdrawSubmitFailed';
export const DIALOGS_WITHDRAW_TOGGLE = 'Dialogs/withdrawToggle';
export const DIALOGS_WITHDRAW_TOGGLE_SHOW = 'Dialogs/withdrawToggleShow';
export const DIALOGS_WITHDRAW_TOGGLE_HIDE = 'Dialogs/withdrawToggleHide';

// GetcourceDialog
export const DIALOGS_GETCOURSE_FORM_SUBMIT = 'Dialogs/getcourseSubmit';
export const DIALOGS_GETCOURSE_FORM_SUBMIT_SUCCESS = 'Dialogs/getcourseSubmitSuccess';
export const DIALOGS_GETCOURSE_FORM_SUBMIT_FAILED = 'Dialogs/getcourseSubmitFailed';
export const DIALOGS_GETCOURSE_TOGGLE = 'Dialogs/getcourseToggle';
export const DIALOGS_GETCOURSE_TOGGLE_SHOW = 'Dialogs/getcourseToggleShow';
export const DIALOGS_GETCOURSE_TOGGLE_HIDE = 'Dialogs/getcourseToggleHide';

// SubscriptionDialog
export const DIALOGS_SUBSCRIPTION_FORM_SUBMIT = 'Dialogs/subscriptionSubmit';
export const DIALOGS_SUBSCRIPTION_FORM_SUBMIT_SUCCESS = 'Dialogs/subscriptionSubmitSuccess';
export const DIALOGS_SUBSCRIPTION_FORM_SUBMIT_FAILED = 'Dialogs/subscriptionSubmitFailed';
export const DIALOGS_SUBSCRIPTION_SUCCESS = 'Dialogs/subscriptionSuccess';
export const DIALOGS_SUBSCRIPTION_TOGGLE = 'Dialogs/subscriptionToggle';
export const DIALOGS_SUBSCRIPTION_TOGGLE_SHOW = 'Dialogs/subscriptionToggleShow';
export const DIALOGS_SUBSCRIPTION_TOGGLE_HIDE = 'Dialogs/subscriptionToggleHide';

// ActivationDialog
export const DIALOGS_ACTIVATION_FORM_SUBMIT = 'Dialogs/activationSubmit';
export const DIALOGS_ACTIVATION_FORM_SUBMIT_SUCCESS = 'Dialogs/activationSubmitSuccess';
export const DIALOGS_ACTIVATION_SUCCESS = 'Dialogs/activationSuccess';
export const DIALOGS_ACTIVATION_TOGGLE = 'Dialogs/activationToggle';
export const DIALOGS_ACTIVATION_TOGGLE_SHOW = 'Dialogs/activationToggleShow';
export const DIALOGS_ACTIVATION_TOGGLE_HIDE = 'Dialogs/activationToggleHide';

// PaymentDialog
export const DIALOGS_PAYMENT_FORM_SUBMIT = 'Dialogs/paymentSubmit';
export const DIALOGS_PAYMENT_FORM_SUBMIT_SUCCESS = 'Dialogs/paymentSubmitSuccess';
export const DIALOGS_PAYMENT_FORM_SUBMIT_FAILED = 'Dialogs/paymentSubmitFailed';
export const DIALOGS_PAYMENT_TOGGLE = 'Dialogs/paymentToggle';
export const DIALOGS_PAYMENT_TOGGLE_SHOW = 'Dialogs/paymentToggleShow';
export const DIALOGS_PAYMENT_TOGGLE_HIDE = 'Dialogs/paymentToggleHide';

// ContactDialog
export const DIALOGS_CONTACT_FORM_SUBMIT = 'Dialogs/contactSubmit';
export const DIALOGS_CONTACT_FORM_SUBMIT_SUCCESS = 'Dialogs/contactSubmitSuccess';
export const DIALOGS_CONTACT_FORM_SUBMIT_FAILED = 'Dialogs/contactSubmitFailed';
export const DIALOGS_CONTACT_TOGGLE = 'Dialogs/contactToggle';
export const DIALOGS_CONTACT_TOGGLE_SHOW = 'Dialogs/contactToggleShow';
export const DIALOGS_CONTACT_TOGGLE_HIDE = 'Dialogs/contactToggleHide';
export const DIALOGS_CONTACT_UPDATE_TOGGLE_SHOW = 'Dialogs/contactUpdateToggleShow';
export const DIALOGS_CONTACT_UPDATE_FORM_SUBMIT = 'Dialogs/contactUpdateSubmit';
export const DIALOGS_CONTACT_UPDATE_SUCCESS = 'Dialogs/contactUpdateSuccess';
export const DIALOGS_CONTACT_UPDATE_FAILED = 'Dialogs/contactUpdateFailed';

// Product
export const PRODUCTS_GET_ITEMS = 'Products/getItems';
export const PRODUCTS_GET_ITEMS_SUCCESS = 'Products/getItemsSuccess';
export const PRODUCTS_GET_ITEMS_FAILED = 'Products/getItemsFailed';
export const PRODUCTS_GET_ITEM = 'Products/getItem';
export const PRODUCTS_GET_ITEM_REQUEST = 'Products/getItemRequest';
export const PRODUCTS_GET_ITEM_REQUEST_SUCCESS = 'Products/getItemRequestSuccess';
export const PRODUCTS_GET_ITEM_REQUEST_SUCCESS_FAILED = 'Products/getItemRequestFailed';

// ProductCategories
export const PRODUCT_CATEGORIES_GET_ITEMS = 'ProductCategories/getItems';
export const PRODUCT_CATEGORIES_GET_ITEMS_SUCCESS = 'ProductCategories/getItemsSuccess';
export const PRODUCT_CATEGORIES_GET_ITEMS_FAILED = 'ProductCategories/getItemsFailed';
