import { createAction } from '@reduxjs/toolkit';
import {
  DIALOGS_WITHDRAW_FORM_SUBMIT,
  DIALOGS_WITHDRAW_FORM_SUBMIT_FAILED,
  DIALOGS_WITHDRAW_FORM_SUBMIT_SUCCESS,
  DIALOGS_WITHDRAW_TOGGLE,
  DIALOGS_WITHDRAW_TOGGLE_HIDE,
  DIALOGS_WITHDRAW_TOGGLE_SHOW,
} from '../../actionTypes';


export const withdrawFormSubmit = createAction<
  unknown,
  typeof DIALOGS_WITHDRAW_FORM_SUBMIT
>(DIALOGS_WITHDRAW_FORM_SUBMIT);

export const withdrawFormSubmitSuccess = createAction<
  void,
  typeof DIALOGS_WITHDRAW_FORM_SUBMIT_SUCCESS
>(DIALOGS_WITHDRAW_FORM_SUBMIT_SUCCESS);

export type withdrawFormSubmitFailedPayload = { message: string }
export const withdrawFormSubmitFailed = createAction<
  withdrawFormSubmitFailedPayload,
  typeof DIALOGS_WITHDRAW_FORM_SUBMIT_FAILED
>(DIALOGS_WITHDRAW_FORM_SUBMIT_FAILED);

export const withdrawDialogToggle = createAction<
  void,
  typeof DIALOGS_WITHDRAW_TOGGLE
>(DIALOGS_WITHDRAW_TOGGLE);

export const withdrawDialogToggleShow = createAction<
  void,
  typeof DIALOGS_WITHDRAW_TOGGLE_SHOW
>(DIALOGS_WITHDRAW_TOGGLE_SHOW);

export const withdrawDialogToggleHide = createAction<
  void,
  typeof DIALOGS_WITHDRAW_TOGGLE_HIDE
>(DIALOGS_WITHDRAW_TOGGLE_HIDE);
