import React, { FC, useCallback } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useDispatch, useSelector } from 'react-redux';
import { snackToggleHide } from '../../store/actions/ThrowSnack';
import { RootState } from '../../store';

const AlertSnackbar: FC = () => {
  const dispatch = useDispatch();
  const {
    isOpen, title, text, type,
  } = useSelector(({ ThrowSnack }: RootState) => ThrowSnack);
  const handleClose = useCallback(
    () => {
      dispatch(snackToggleHide());
    },
    [dispatch],
  );

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={type}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {text}
      </Alert>
    </Snackbar>
  );
};

AlertSnackbar.defaultProps = {};

export default AlertSnackbar;
