import { createStyles, Theme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { darken, rgba } from 'polished';


export const drawerWidth = 260;
export const activeClassName = 'active';

export default (theme: Theme) => createStyles({
  brand: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.sidebar.header.color,
    backgroundColor: theme.sidebar.header.background,
    fontFamily: theme.typography.fontFamily,
    minHeight: 56,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),

    [theme.breakpoints.up('sm')]: {
      minHeight: 64,
    },
  },
  drawer: {
    borderRight: 0,
    '& > div': {
      borderRight: 0,
    },
  },
  scrollbar: {
    backgroundColor: theme.sidebar.background,
    borderRight: `1px solid ${rgba(0, 0, 0, 0.12)}`,
    height: '100%',
  },
  list: {
    borderRight: `1px solid ${rgba(0, 0, 0, 0.12)}`,
    height: '100%',
    backgroundColor: theme.sidebar.background,
    overflowY: 'auto',
  },
  brandIcon: {
    marginRight: theme.spacing(2),
    color: theme.sidebar.header.brand.color,
  },
  brandChip: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
    color: blue[700],
    fontSize: '60%',
    height: 20,
    marginLeft: 2,
    marginBottom: 1,
    padding: theme.spacing(1, 0),

    '& span': {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
  sidebarFooter: {
    backgroundColor: `${theme.sidebar.footer.background} !important`,
    borderRight: `1px solid ${rgba(0, 0, 0, 0.12)}`,
  },
  sidebarFooterText: {
    color: theme.sidebar.footer.color,
  },
  sidebarFooterSubText: {
    color: theme.sidebar.footer.color,
    fontSize: '0.725rem',
    display: 'block',
    padding: 1,
  },
  category: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(5),
    fontWeight: theme.typography.fontWeightRegular,

    '& svg': {
      color: theme.sidebar.color,
      fontSize: 20,
      width: 20,
      height: 20,
      opacity: 0.5,
    },

    '&:hover': {
      background: rgba(0, 0, 0, 0.08),
    },

    [`&.${activeClassName}`]: {
      backgroundColor: darken(0.05, theme.sidebar.background),

      '& span': {
        color: theme.sidebar.color,
      },
    },
  },
  categoryText: {
    margin: 0,
    '& span': {
      color: theme.sidebar.color,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.sidebar.category.fontWeight,
      padding: theme.spacing(0, 4),
    },
  },
  categoryIcon: {
    color: rgba(theme.sidebar.color, 0.5),
  },
  categoryLink: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: '49px',
    paddingRight: theme.spacing(5),
    fontWeight: theme.typography.fontWeightRegular,

    '& svg': {
      color: theme.sidebar.color,
      fontSize: 20,
      width: 20,
      height: 20,
      opacity: 0.5,
    },

    '&:hover': {
      background: rgba(0, 0, 0, 0.08),
    },

    [`&.${activeClassName}`]: {
      backgroundColor: darken(0.05, theme.sidebar.background),

      '& span': {
        color: theme.sidebar.color,
      },
    },
  },
  linkBadge: {
    fontSize: 11,
    fontWeight: theme.typography.fontWeightBold,
    height: 20,
    position: 'absolute',
    right: 12,
    top: 8,
    background: theme.sidebar.badge.background,

    '& span.MuiChip-label, & span.MuiChip-label:hover': {
      cursor: 'pointer',
      color: theme.sidebar.badge.color,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
});
