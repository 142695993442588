import React, { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import { Typography, makeStyles, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import styles from './styles';


const useStyles = makeStyles(styles, { name: 'Page404' });

const Page404: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <Helmet title="Страница не найдена" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        {t('page_not_found')}
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        {t('page_not_found_hint')}
      </Typography>

      <Button
        className={classes.button}
        component={Link}
        to="/"
        variant="contained"
        color="secondary"
      >
        {t('go_back_to_site')}
      </Button>
    </div>
  );
};

export default memo(Page404);
