/* eslint-disable @typescript-eslint/explicit-function-return-type */
import defaultReducerFunc from '../../../utils/defaultReducerFunc';
import {
  getcourseDialogToggle,
  getcourseDialogToggleHide,
  getcourseDialogToggleShow,
  getcourseFormSubmitSuccess,
} from '../../actions/Dialogs';
import { GetcourseDialogState } from '../../interfaces/Dialogs';


const INITIAL_STATE: GetcourseDialogState = {
  isOpen: false,
};

const actionsMap = [
  {
    action: getcourseDialogToggle,
    reducer: defaultReducerFunc,
  },
  {
    action: getcourseDialogToggleShow,
    reducer: (state: GetcourseDialogState) => ({
      ...state,
      isOpen: true,
    }),
  },
  {
    action: getcourseDialogToggleHide,
    reducer: (state: GetcourseDialogState) => ({
      ...state,
      isOpen: false,
    }),
  },
  {
    action: getcourseFormSubmitSuccess,
    reducer: (state: GetcourseDialogState) => ({
      ...state,
      isOpen: false,
    }),
  },
];

export default { INITIAL_STATE, actionsMap };
