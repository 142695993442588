import React, { useCallback, useState, FC } from 'react';
import { useMount } from 'react-use';
import { Controller } from 'react-hook-form';
import { Select, MenuItem } from '@material-ui/core';
import { get } from 'lodash';
import { SelectFieldProps } from '../types';
import fetchApi from '../../../utils/fetchApi';


const SelectField: FC<SelectFieldProps> = (props) => {
  const {
    id,
    name,
    choicesUrl,
    control,
    error,
  } = props;
  const [options, setOptions] = useState([]);
  const [currentValue, setCurrentValue] = React.useState('');
  useMount(async () => {
    const { response, status } = await fetchApi({
      method: 'GET',
      payloadUrl: choicesUrl,
    });
    if (status === 200) {
      // @ts-ignore
      setOptions(response.choices);
      // @ts-ignore
      setCurrentValue(get(response, 'choices[0][0]', ''));
    }
  });

  const handleChange = useCallback(
    (event) => {
      setCurrentValue(event.target.value);
    },
    [],
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      as={(
        <Select
          labelId={name}
          id={id}
          name={name}
          value={currentValue}
          onChange={handleChange}
          error={!!error}
          fullWidth
        >
          {options.map((
            [optionId, optionLabel],
          ) => <MenuItem value={optionId} key={optionId}>{optionLabel}</MenuItem>)}
        </Select>
      )}
    />
  );
};


export default SelectField;
