import { FormContextValues } from 'react-hook-form';
import { isArray, camelCase } from 'lodash';


// eslint-disable-next-line import/prefer-default-export
export const parseForHookForm = <R>(response: R, callBack: FormContextValues['setError']): void => {
  Object.entries(response).forEach(([name, message]) => {
    const tempName = camelCase(name);
    if (typeof message === 'string') {
      callBack(
        tempName,
        `response-${tempName}`,
        message,
      );
    } else if (isArray(message)) {
      callBack(
        message.map((error, index) => ({
          name: tempName,
          type: `response-${tempName}-${index}`,
          message: error,
        })),
      );
    }
  });
};
