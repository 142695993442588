/* eslint-disable max-len */
import { createAction } from '@reduxjs/toolkit';

import {
  PRODUCTS_GET_ITEMS,
  PRODUCTS_GET_ITEMS_SUCCESS,
  PRODUCTS_GET_ITEMS_FAILED,
  PRODUCTS_GET_ITEM,
  PRODUCTS_GET_ITEM_REQUEST,
  PRODUCTS_GET_ITEM_REQUEST_SUCCESS,
  PRODUCTS_GET_ITEM_REQUEST_SUCCESS_FAILED,
} from '../actionTypes';
import { Product } from '../interfaces/Products';
import { GetProductsAPIResponse } from '../../interfaces/Products';


// Items
export type productsGetItemsPayload = { category: string; page: number }
export const productsGetItems = createAction<
  productsGetItemsPayload,
  typeof PRODUCTS_GET_ITEMS
>(PRODUCTS_GET_ITEMS);

export const productsGetItemsSuccess = createAction<
  GetProductsAPIResponse,
  typeof PRODUCTS_GET_ITEMS_SUCCESS
>(PRODUCTS_GET_ITEMS_SUCCESS);

export type productsGetItemsFailedPayload = { message: string }
export const productsGetItemsFailed = createAction<
  productsGetItemsFailedPayload,
  typeof PRODUCTS_GET_ITEMS_FAILED
>(PRODUCTS_GET_ITEMS_FAILED);

// Item
export const productsGetItem = createAction<
  number,
  typeof PRODUCTS_GET_ITEM
>(PRODUCTS_GET_ITEM);

// Item Request
export const productsGetItemRequest = createAction<
  number,
  typeof PRODUCTS_GET_ITEM_REQUEST
>(PRODUCTS_GET_ITEM_REQUEST);

export const productsGetItemRequestSuccess = createAction<
  Product,
  typeof PRODUCTS_GET_ITEM_REQUEST_SUCCESS
>(PRODUCTS_GET_ITEM_REQUEST_SUCCESS);

export type productsGetItemRequestFailedPayload = { message: string }
export const productsGetItemRequestFailed = createAction<
productsGetItemRequestFailedPayload,
  typeof PRODUCTS_GET_ITEM_REQUEST_SUCCESS_FAILED
>(PRODUCTS_GET_ITEM_REQUEST_SUCCESS_FAILED);
