import { combineReducers } from 'redux';
import {
  PERSIST, persistReducer, persistStore, REHYDRATE,
} from 'redux-persist';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from './sagas';
import Auth from './reducers/Auth';
import Users from './reducers/Users';
import Products from './reducers/Product';
import ProductCategories from './reducers/ProductCategories';
import ThrowSnack from './reducers/ThrowSnack';
import Dialogs from './reducers/Dialogs';
import PaymentDialog from './reducers/paymentDialog';

import { profileUpdate } from './actions/Users';
import {
  getcourseFormSubmit,
  subscriptionFormSubmit,
  withdrawFormSubmit,
  contactFormSubmit,
} from './actions/Dialogs';
import { paymentFormSubmit } from './actions/Dialogs/paymentDialog';

const authConfig = {
  key: 'auth',
  storage,
  whitelist: ['token'],
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const rootReducer = combineReducers({
  Auth: persistReducer(authConfig, Auth),
  Users,
  Products,
  ProductCategories,
  ThrowSnack,
  Dialogs,
  PaymentDialog,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: {
      ignoredActions: [
        PERSIST, REHYDRATE,
        profileUpdate.type,
        withdrawFormSubmit.type,
        getcourseFormSubmit.type,
        subscriptionFormSubmit.type,
        paymentFormSubmit.type,
        contactFormSubmit.type,
      ],
    },
  }),
  sagaMiddleware,
];

const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export default store;
export type RootState = ReturnType<typeof persistedReducer>
